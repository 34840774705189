import React, { useState } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";

const Invoice = ({ order, size }) => {
  const [paperSize, setPaperSize] = useState(size);

  const handlePrint = () => {
    window.print();
  };

  const handleGeneratePDF = () => {
    const doc = new jsPDF();

    doc.text(order.restaurant.name, 10, 10);
    doc.text(order.restaurant.address, 10, 20);

    doc.text(`Order ID: ${order.id}`, 10, 30);
    doc.text(`Date: ${new Date(order.createdAt).toLocaleDateString()}`, 10, 40);
    doc.text(`Status: ${order.status}`, 10, 50);
    doc.text(`Order Type: ${order.orderType}`, 10, 60);

    doc.text(`Customer Phone: ${order.customer.phone}`, 10, 70);
    doc.text(`Customer Address: ${order.customer.address || "N/A"}`, 10, 80);

    const orderItems = order.orderItems.map((item) => [
      item.menuItem.name,
      item.quantity,
      item.price,
    ]);
    doc.autoTable({
      head: [["Item", "Qty", "Price"]],
      body: orderItems,
      startY: 90,
    });

    const yPosition = doc.previousAutoTable.finalY + 10;
    doc.text(`Base Price: ₹${order.basePriceTotal}`, 10, yPosition);
    doc.text(`Discount: ₹${order.discountAmount}`, 10, yPosition + 10);
    doc.text(`Tax: ₹${order.taxAmount}`, 10, yPosition + 20);
    doc.text(
      `Service Charge: ₹${order.serviceChargeAmount}`,
      10,
      yPosition + 30
    );
    doc.text(`Total Amount: ₹${order.totalAmount}`, 10, yPosition + 40);

    doc.save(`invoice_${order.id}.pdf`);
  };

  return (
    <div className={`invoice-container ${paperSize}`}>
      <div className="restaurant-info">
        <h2>{order.restaurant.name}</h2>
        <p>{order.restaurant.address}</p>
      </div>

      <div className="order-info">
        <p>Order ID: {order.id}</p>
        <p>Date: {new Date(order.createdAt).toLocaleDateString()}</p>
        <p>Status: {order.status}</p>
        <p>Order Type: {order.orderType}</p>
      </div>

      <div className="customer-info">
        <p>Customer Phone: {order.customer?.phone}</p>
        <p>Customer Address: {order.customer?.address || "N/A"}</p>
      </div>

      <div className="order-items">
        {order.orderItems.map((item, index) => (
          <div key={index} className="order-item">
            <p>{item.menuItem.name}</p>
            <p>Qty: {item.quantity}</p>
            <p>Price: ₹{item.price}</p>
          </div>
        ))}
      </div>

      <div className="totals">
        <p>Base Price: ₹{order.basePriceTotal}</p>
        <p>Discount: ₹{order.discountAmount}</p>
        <p>Tax: ₹{order.taxAmount}</p>
        <p>Service Charge: ₹{order.serviceChargeAmount}</p>
        <p>Total Amount: ₹{order.totalAmount}</p>
      </div>

      <div className="print:hidden">
        <button
          onClick={handlePrint}
          className="mt-4 p-2 bg-blue-500 text-white rounded"
        >
          Print Invoice
        </button>
        <button
          onClick={handleGeneratePDF}
          className="mt-4 p-2 bg-green-500 text-white rounded"
        >
          Download PDF
        </button>
      </div>
    </div>
  );
};

export default Invoice;
