import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import PageLoading from "../componentsOwner/PageLoading";
import { BASE_URL } from "../utils/services";
import Loading from "../components/Loading";
import CategoryCrud from "./CategoryCrud";
import { MdEdit, MdDelete } from "react-icons/md";
import pluralize from "pluralize";
import { FaEye } from "react-icons/fa";
import FloatingAddButton from "../components/FloatingAddButton";
const MenuCategories = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [crudData, setCrudData] = useState(null);
  const [categories, setCategories] = useState(null);
  const [error, setError] = useState(null);
  const loadCategories = async () => {
    setLoading(true);
    try {
      const res = await fetch(`${BASE_URL}/owner/menu-categories`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      const data = await res.json();
      // console.log(data);
      if (data) {
        setCategories(data.categories);
      }

      if (res.status === 401) {
        navigate("/owner-login");
      }

      if (!res.status === 200) {
        const error = new Error(res.error);
        throw error;
      }
    } catch (error) {
      console.log(error);
      setError("Failed to load categories!");
    } finally {
      setLoading(false);
    }
  };
  const handleDelete = async (id) => {
    //   e.preventDefault();
    const userConfirmed = window.confirm(
      `Are you sure you want to Delete this category?`
    );

    if (!userConfirmed) {
      return; // Exit the function if the user does not confirm
    }
    setLoading(true);
    try {
      const res = await fetch(`${BASE_URL}/owner/delete-menu-category/${id}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (res.status === 200) {
        setError(null);
        window.alert("Category Deleted Successfully.");
        loadCategories();
      } else if (res.status === 403) {
        setError("Unauthorized Access.");
      } else if (res.status === 400) {
        setError("Category not found");
      } else if (res.status === 400) {
        setError("Category has menu items");
      } else {
        setError("Failed to delete category. Please try again.");
      }

      if (res.status === 401) {
        navigate("/owner-login");
      }
    } catch (error) {
      console.log(error);
      setError("Failed to load categories!");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    loadCategories();
  }, []);

  const handleCrudModal = (id, action = "create") => {
    // e.preventDefault();

    setCrudData({ action, data: action === "create" ? null : id });
    setShowModal(true);
  };

  if (categories) {
    return (
      <div className="flex flex-col justify-center items-center bg-gray-100">
        {loading && <Loading />}

        <div className="w-full bg-white shadow-md rounded-lg p-4 h-max">
          <div className="flex justify-between items-center mb-4 w-full ">
            <p className="text-xl text-gray-700 font-bold">Categories</p>
          </div>
          {showModal && (
            <CategoryCrud
              action={crudData?.action}
              data={crudData?.data}
              onClose={() => {
                setShowModal(false);
                setCrudData(null);
                loadCategories();
              }}
            />
          )}
          {error && <div className="text-red-500 mb-4">{error}</div>}
          {categories.map((item, index) => (
            <div
              className="flex justify-between sm:justify-start items-start gap-2 w-full mb-4"
              key={index}
            >
              <div>
                <p className="text-sm font-semibold">
                  {index + 1}. {item.name}{" "}
                  {item.menuItemCount > 0 &&
                    `(${pluralize("item", item.menuItemCount, true)})`}
                </p>
                <p className="text-xs text-gray-600">{item.description}</p>
              </div>
              <div className="flex justify-start gap-2">
                <FaEye
                  className="cursor-pointer text-gray-600 hover:text-gray-800"
                  size={20}
                  title="See Details"
                  onClick={() => handleCrudModal(item.id, "detail")}
                />
                <MdEdit
                  className="cursor-pointer text-edit hover:text-editHover"
                  size={20}
                  title="Edit"
                  onClick={() => handleCrudModal(item.id, "edit")}
                />
                {item.menuItemCount === 0 && (
                  <MdDelete
                    className="cursor-pointer text-danger hover:text-dangerHover"
                    size={20}
                    title="Delete"
                    onClick={() => handleDelete(item.id)}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
        <FloatingAddButton
          title="Add Restaurant"
          onClick={() => handleCrudModal()}
        ></FloatingAddButton>
      </div>
    );
  } else {
    return (
      <>
        <PageLoading link={"/"} />
      </>
    );
  }
};

export default MenuCategories;
