import React, { useCallback, useEffect, useRef, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { MdArrowBack, MdClose, MdDelete } from "react-icons/md";

import { Link, useParams } from "react-router-dom";
import { BASE_URL } from "../../utils/services";
import PageLoading from "../../componentsOwner/PageLoading";
import debounce from "lodash/debounce";
import moment from "moment";
import Loading from "../../components/Loading";
import { foodType, formatCurrency } from "../../utils/helper";
import PaymentComponent from "../../componentsOwner/PaymentComponent";
import { IoIosList, IoIosListBox } from "react-icons/io";
import PosOrderList from "../../componentsOwner/PosOrderList";
import OrderDetailModal from "../../componentsOwner/OrderDetailModal";
// import { useThemeContext } from "../../context/ThemeContext";
const INIT_ORDER_TYPE = "DINE-IN";
const INIT_DISCOUNT = { type: "FIXED", value: 0 };
const Pos = () => {
  // const { setIsMenuOpen, isMenuOpen } = useThemeContext();
  const { restaurantId } = useParams();
  const [totals, setTotals] = useState({
    basePriceTotal: 0,
    discountAmount: 0,
    taxableAmount: 0,
    taxAmount: 0,
    serviceChargeAmount: 0,
    totalAmount: 0,
    roundOff: 0,
  });
  const [escapeCount, setEscapeCount] = useState(0);
  const [mobile, setMobile] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [orderType, setOrderType] = useState(""); // Track order type
  const [metaData, setMetaData] = useState(null); // Track order type
  const [tableNumber, setTableNumber] = useState(""); // For Dine-in
  const [selectedCategory, setSelectedCategory] = useState("all"); // Track selected category
  const [searchQuery, setSearchQuery] = useState(""); // Search input
  const [menuItems, setMenuItems] = useState([]); // List of items
  const [cartItems, setCartItems] = useState([]); // Items in cart

  // let totalPrice = 0;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [finalReviewModal, setFinalReviewModal] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [restaurant, setRestaurant] = useState(null);
  const [draftIds, setDraftIds] = useState(null);
  const [activeDraftId, setActiveDraftId] = useState(null);
  const [discount, setDiscount] = useState(INIT_DISCOUNT);
  const [orderBar, setOrderBar] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const openViewModal = (orderId) => {
    setSelectedOrderId(orderId);
    setIsViewModalOpen(true);
  };

  const closeViewModal = () => {
    setIsViewModalOpen(false);
    setSelectedOrderId(null);
  };
  // useEffect(() => {
  //   // Initialize metaData based on orderType
  //   if (activeDraftId) return;
  //   if (orderType === "DELIVERY") {
  //     setMetaData([{ dataName: "Address", dataValue: "" }]);
  //   } else if (orderType === "MARKETPLACE") {
  //     setMetaData([
  //       { dataName: "Marketplace", dataValue: "" },
  //       { dataName: "Order ID", dataValue: "" },
  //     ]);
  //   } else {
  //     setMetaData([]);
  //   }
  // }, [orderType]);

  const handleMobile = (e) => {
    let value = e.target.value;
    if (isNaN(value)) {
      return;
    }
    if (value.length > 10) {
      value = value.slice(0, 10);
    }
    if (value === "" || value == null || value.length === 10) {
      setMobileError("");
    } else {
      setMobileError("Incomplete Mobile number");
    }
    setMobile(value);
  };
  const handleMetaChange = (index, event) => {
    const newMetaData = [...metaData];
    newMetaData[index].dataValue = event.target.value;
    setMetaData(newMetaData);
  };

  const payNowRef = useRef(null);
  const orderListRef = useRef(null);
  function refreshOrderList() {
    if (orderListRef.current) {
      orderListRef.current.refresh();
    }
  }
  const handleOrderType = (type) => {
    setOrderType(type);
    setTableNumber("");
    if (type === "DELIVERY") {
      setMetaData([{ dataName: "Address", dataValue: "" }]);
    } else if (type === "MARKETPLACE") {
      setMetaData([
        { dataName: "Marketplace", dataValue: "" },
        { dataName: "Order ID", dataValue: "" },
      ]);
    } else if (type === "ROOM") {
      setMetaData([{ dataName: "Room No", dataValue: "" }]);
    } else {
      setMetaData([]);
    }
    // setMetaData(null);
  };

  const handleCartItem = (item, action) => {
    setCartItems((prevCartItems) => {
      const existingItemIndex = prevCartItems.findIndex(
        (cartItem) => cartItem.id === item.id
      );

      let updatedCartItems = [...prevCartItems];

      if (existingItemIndex >= 0) {
        const existingItem = updatedCartItems[existingItemIndex];

        if (action === "increment") {
          updatedCartItems[existingItemIndex] = {
            ...existingItem,
            quantity: existingItem.quantity + 1,
          };
        } else if (action === "decrement") {
          if (existingItem.quantity > 1) {
            updatedCartItems[existingItemIndex] = {
              ...existingItem,
              quantity: existingItem.quantity - 1,
            };
          } else {
            updatedCartItems.splice(existingItemIndex, 1);
          }
        } else if (action === "remove") {
          updatedCartItems.splice(existingItemIndex, 1);
        } else if (action === "remarks") {
          updatedCartItems[existingItemIndex] = {
            ...existingItem,
            remarks: item.remarks,
          };
        }
      } else if (action === "add") {
        updatedCartItems.push({ ...item, quantity: 1 });
      }
      // calculateTotalPrice();

      return updatedCartItems;
    });
  };

  // Example of how to use the handleCartItem function
  const addItemToCart = (item) => handleCartItem(item, "add");
  const incrementItemQuantity = (item) => handleCartItem(item, "increment");
  const decrementItemQuantity = (item) => handleCartItem(item, "decrement");
  const removeItemFromCart = (item) => handleCartItem(item, "remove");
  const updateItemRemarks = (item, remarks) =>
    handleCartItem({ ...item, remarks }, "remarks");
  const fetchMenuItems = async (categoryId, searchQuery = "") => {
    if (!categoryId) return;

    try {
      setLoading(true);
      let url = `${BASE_URL}/owner/pos/${restaurantId}/menu-items/${categoryId}`;
      if (searchQuery) {
        url += `?search=${encodeURIComponent(searchQuery)}`;
      }
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();
      setMenuItems(data);
    } catch (error) {
      console.error("Error fetching menu items:", error);
    } finally {
      setLoading(false);
    }
  };

  // Debounced version of fetchMenuItems using lodash
  const debouncedFetchMenuItems = useCallback(
    debounce(fetchMenuItems, 500, { leading: false, trailing: true }), // Options can be customized
    []
  );

  // Update menu items when the selected category changes
  useEffect(() => {
    if (selectedCategory) {
      debouncedFetchMenuItems(selectedCategory, searchQuery);
    }
  }, [selectedCategory, searchQuery, debouncedFetchMenuItems]);
  useEffect(() => {
    let timer;
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setEscapeCount((prevCount) => {
          const newCount = prevCount + 1;
          if (newCount === 2) {
            resetOrderData();
          }
          return newCount === 2 ? 0 : newCount;
        });
      }
    };
    if (escapeCount > 0) {
      // Start or reset the timer to clear the count after a delay
      timer = setTimeout(() => setEscapeCount(0), 500); // 500ms delay
    }

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      clearTimeout(timer); // Cleanup timer on component unmount
    };
  }, [escapeCount]);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const fetchRestaurant = useCallback(async () => {
    try {
      setLoading(true);
      const res = await fetch(`${BASE_URL}/owner/pos/${restaurantId}`, {
        method: "GET",
        credentials: "include",
      });
      const data = await res.json();
      if (res.status === 200) {
        setRestaurant({
          ...data.restaurant,
          categories: data.categories,
          orderTypes: data.ORDER_TYPES,
        });
        await fetchDrafts();
        //   setCategories(data.categories);
      } else if (res.status === 403) {
        window.alert("Unauthorized Access.");
      } else {
        setError("Failed to fetch restaurant details");
      }
    } catch (error) {
      setError("Failed to fetch restaurant details");
    } finally {
      setLoading(false);
    }
  }, [restaurantId]);
  const saveDraft = async (draftId) => {
    try {
      setLoading(true);
      const response = await fetch(
        `${BASE_URL}/owner/pos/${restaurantId}/save-draft`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            draftId,
            restaurantId,
            orderData: {
              orderType,
              tableNumber,
              cartItems,
              // totalPrice,
              metaData,
              mobile,
              discount,
            },
          }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        await fetchDrafts();
        resetOrderData();
        alert("Draft saved successfully!");
      } else {
        console.error(data.error);
      }
    } catch (error) {
      console.error("Failed to save draft:", error);
      setError("Failed to save draft");
    } finally {
      setLoading(false);
    }
  };

  const fetchDrafts = async () => {
    try {
      setLoading(true);

      const res = await fetch(
        `${BASE_URL}/owner/pos/${restaurantId}/fetch-drafts`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      const data = await res.json();
      setDraftIds(data);
    } catch (error) {
      console.error("Error fetching drafts:", error);
      setError("Failed to fetch drafts");
    } finally {
      setLoading(false);
    }
  };
  const loadDraft = async (draftId) => {
    try {
      setLoading(true);

      const response = await fetch(
        `${BASE_URL}/owner/pos/${restaurantId}/fetch-draft/${draftId}`,
        { method: "GET", credentials: "include" }
      );
      const draft = await response.json();
      if (response.ok) {
        setOrderData(draft.orderData);
      } else {
        console.error(draft.error);
      }
    } catch (error) {
      console.error("Failed to load draft:", error);
    } finally {
      setLoading(false);
    }
  };
  const deleteDraft = async (draftId) => {
    try {
      setLoading(true);

      const response = await fetch(
        `${BASE_URL}/owner/pos/${restaurantId}/delete-draft/${draftId}`,
        { method: "DELETE", credentials: "include" }
      );
      const data = await response.json();
      if (response.ok) {
        await fetchDrafts();
        resetOrderData();
        // alert("Draft deleted successfully!");
      } else {
        console.error(data.error);
      }
    } catch (error) {
      console.error("Failed to delete draft:", error);
      setError("Failed to delete draft");
    } finally {
      setLoading(false);
    }
  };
  const setOrderData = (orderData) => {
    if (orderData) {
      setOrderType(orderData.orderType);
      setTableNumber(orderData.tableNumber);
      setCartItems(orderData.cartItems);
      // setTotalPrice(orderData.totalPrice);
      setMetaData(orderData.metaData);
      setMobile(orderData.mobile);
      setDiscount(orderData.discount || INIT_DISCOUNT);
    } else {
      setOrderType(INIT_ORDER_TYPE);
      setTableNumber("");
      setCartItems([]);
      // setTotalPrice(0);
      setMetaData(null);
      setMobile("");
      setDiscount(INIT_DISCOUNT);
    }
    // calculateTotalPrice();
  };
  const resetOrderData = () => {
    if (activeDraftId) setActiveDraftId(null);
    else setOrderData();
  };
  const placeOrder = async () => {
    // if (!mobile) {
    //   const confirm = window.confirm(
    //     "Mobile number not taken!!! \nAre you sure you want to place the order?"
    //   );
    //   if (!confirm) return;
    // }
    if (mobileError !== "") {
      return;
    }
    try {
      setLoading(true);
      const response = await fetch(
        `${BASE_URL}/owner/pos/${restaurantId}/place-order`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            orderData: {
              orderType,
              tableNumber,
              cartItems,
              // totalPrice,
              metaData,
              mobile,
              discount,
              activeDraftId,
            },
            paymentData:
              restaurant?.paymentFlow === "PRE"
                ? payNowRef.current.getPayingNow()
                : null,
          }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        await fetchDrafts();
        resetOrderData();
        refreshOrderList();
        setFinalReviewModal(false);
        // alert("Order placed successfully!");
        openViewModal(data.id);
      } else {
        console.error(data.error);
      }
    } catch (ex) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    // const menuOpen = isMenuOpen;
    // setIsMenuOpen(false);
    fetchRestaurant();
    return () => {
      // setIsMenuOpen(menuOpen);
      setRestaurant(null);
    };
  }, [restaurantId, fetchRestaurant]);
  useEffect(() => {
    if (activeDraftId) {
      loadDraft(activeDraftId);
    } else {
      setOrderData(null);
    }
    // calculateTotalPrice();
  }, [activeDraftId]);

  useEffect(() => {
    const calculateTotals = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}/owner/pos/${restaurantId}/totals`,
          {
            method: "POST",
            credentials: "include",

            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ cartItems, discount }),
          }
        );
        const data = await response.json();

        // Update the totals state with the data returned from the API
        setTotals(data);
      } catch (error) {
        console.error("Error fetching totals:", error);
      }
    };

    calculateTotals();
  }, [cartItems, discount]);

  if (!restaurant) {
    return (
      <>
        <PageLoading link={"/owner/owner-home"} />
        {error && <p className="text-center text-red-500">{error}</p>}
      </>
    );
  }

  return (
    <div className=" h-screen max-h-screen flex flex-col relative bg-white">
      {/* Header with Quick Access */}
      <header className="bg-white shadow p-4 flex flex-col sm:flex-row justify-between items-center space-y-2 sm:space-y-0">
        <div className="flex flex-col w-full sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4">
          <div className="w-full flex justify-between items-center sm:hidden">
            <Link
              to={`/owner/restaurant/${restaurantId}`}
              className="text-reviews inline-flex items-center"
            >
              <MdArrowBack size={20} className="mr-2" /> Back
            </Link>
            <h1 className="text-left text-xl font-bold">{restaurant.name}</h1>
            <button onClick={openModal} className="sm:hidden">
              <FiSearch size={24} />
            </button>
            <button className="text-available sm:hidden">
              <IoIosList title="All Orders" size={24} />
            </button>
          </div>
          <div className="w-full sm:w-1/3">
            <input
              type="text"
              placeholder="Enter Mobile Number"
              className={`border rounded p-2 w-full ${
                mobileError === "" ? "border-gray-500" : "border-red-300"
              }`}
              maxLength={10}
              value={mobile}
              onChange={handleMobile}
            />
            <p className="text-xs text-red-600">{mobileError}</p>
          </div>

          <h1 className="text-center text-xl font-bold hidden sm:block sm:w-1/3">
            {restaurant.name}
          </h1>
          <div className="w-full sm:w-1/3 flex justify-end">
            <input
              type="text"
              placeholder="Search by Order Number"
              className="border border-gray-300 rounded p-2 w-full hidden sm:block"
            />
            <button
              className="text-black hidden sm:block"
              onClick={() => setOrderBar((prev) => !prev)}
              title="All Orders"
            >
              {orderBar ? <IoIosListBox size={36} /> : <IoIosList size={36} />}
            </button>
          </div>
        </div>
      </header>

      {/* Main Order Creation Section */}
      <div className="flex flex-1 flex-col sm:flex-row">
        <main className="flex-1 p-4">
          {/* Order Type Selection */}
          <div className="mb-2">
            <h2 className="text-sm font-semibold mb-2">Select Order Type</h2>
            <div className="py-2 flex justify-between sm:block ">
              {/* Buttons for Order Type Selection */}
              {restaurant &&
                Object.values(restaurant?.orderTypes).map((type) => (
                  <button
                    className={`py-2 px-2 border-b-4 border-b-${type.metadata.buttonColor}-700 bg-${type.metadata.buttonColor}-500 text-white sm:w-auto hover:border-b-0 disabled:border-b-0  text-sm`}
                    onClick={() => handleOrderType(type.value)}
                    disabled={orderType === type.value}
                    key={type.value}
                    type="button"
                  >
                    {type.value}
                  </button>
                ))}
            </div>
          </div>

          {/* Dynamic Content Area */}
          <div className="mb-4">
            {metaData && metaData.length > 0 && (
              <div className="flex flex-wrap gap-2">
                {metaData.map((item, index) => (
                  <div key={index} className="flex-item flex-auto">
                    <label className="block mb-2">{item.dataName}</label>
                    {item.dataName === "Address" && (
                      <textarea
                        value={item.dataValue}
                        onChange={(e) => handleMetaChange(index, e)}
                        className="border border-gray-300 rounded p-2 w-full"
                        rows={2} // Adjust rows for Address
                      />
                    )}
                    {item.dataName === "Marketplace" && (
                      <select
                        className="border border-gray-300 rounded p-[10px] w-full"
                        value={item.value}
                        onChange={(e) => handleMetaChange(index, e)}
                      >
                        <option value="">Select a marketplace</option>
                        {restaurant &&
                          restaurant.orderTypes.MARKETPLACE.metadata.markets.map(
                            (marketplace, i) => (
                              <option key={i} value={marketplace}>
                                {marketplace}
                              </option>
                            )
                          )}
                      </select>
                    )}
                    {!["Address", "Marketplace"].includes(item.dataName) && (
                      <input
                        type="text"
                        value={item.dataValue}
                        onChange={(e) => handleMetaChange(index, e)}
                        className="border border-gray-300 rounded p-2 w-full"
                      />
                    )}
                  </div>
                ))}
              </div>
            )}

            {orderType === "DINE-IN" && (
              <div className="mb-4">
                <label className="block mb-2">Table Number</label>
                <select
                  className="border border-gray-300 rounded p-2 w-full"
                  value={tableNumber}
                  onChange={(e) => {
                    setTableNumber(e.target.value);
                  }}
                >
                  <option value="">Select a table</option>
                  {restaurant &&
                    restaurant.tables.map((table) => (
                      <option
                        key={table.id}
                        value={table.id}
                        label={`${table.tableNumber} | ${table.status} `}
                        disabled={table.status === "OCCUPIED"}
                      />
                    ))}
                </select>
              </div>
            )}
          </div>

          {/* Categories and Item Menu */}
          <div className="flex flex-col sm:flex-row">
            {/* Categories Menu */}
            <div className="w-full sm:w-1/4">
              <h3 className="text-lg font-semibold mb-2">Categories</h3>
              <ul className="space-y-2 font-semibold">
                <li key={0}>
                  <button
                    className="w-full text-left py-2 px-4 bg-gray-100 rounded hover:bg-theme/75 disabled:bg-themeDisabled hover:text-themeTextHover disabled:text-themeTextSelected"
                    disabled={selectedCategory === "all"}
                    onClick={() => setSelectedCategory("all")}
                  >
                    All
                  </button>
                </li>
                {restaurant &&
                  restaurant.categories.map((category) => (
                    <li key={category.id}>
                      <button
                        className="w-full text-left py-2 px-4 bg-gray-100 rounded hover:bg-theme/75 disabled:bg-themeDisabled hover:text-themeTextHover disabled:text-themeTextSelected"
                        disabled={selectedCategory === category.id}
                        onClick={() => setSelectedCategory(category.id)}
                      >
                        {category.name}
                      </button>
                    </li>
                  ))}
              </ul>
            </div>

            {/* Items Menu */}
            <div className="w-full sm:w-3/4 sm:pl-4">
              <div className="relative mb-4">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full pr-10 pl-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Search menu items"
                />
                {searchQuery && (
                  <button
                    type="button"
                    onClick={() => setSearchQuery("")}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"
                  >
                    &#x2715;
                  </button>
                )}
              </div>

              {/* Items List */}
              <div className="overflow-auto max-h-svh">
                {menuItems && menuItems.length > 0 ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {menuItems.map((item) => (
                      <div
                        key={item.id}
                        className={`flex flex-col justify-between border p-2 rounded border-gray-300`}
                      >
                        <div>
                          <h4 className="font-semibold inline-flex items-center">
                            {item.name}
                            {foodType(item.itemType, 16)}
                          </h4>
                          <p className="text-gray-500">{item.description}</p>
                          <p className="text-gray-900 font-bold">
                            {formatCurrency(
                              item.pricings[0].price,
                              restaurant.currency
                            )}
                          </p>
                        </div>
                        {cartItems.find(
                          (cartItem) => cartItem.id === item.id
                        ) ? (
                          <div className="flex space-x-2">
                            <button
                              className="py-1 px-3 bg-green-500 text-white rounded"
                              onClick={() => incrementItemQuantity(item)}
                            >
                              +
                            </button>
                            <button
                              className="py-1 px-3 bg-red-500 text-white rounded"
                              onClick={() => decrementItemQuantity(item)}
                            >
                              -
                            </button>
                            <button
                              className="py-1 px-3 bg-gray-500 text-white rounded"
                              onClick={() => removeItemFromCart(item)}
                            >
                              <MdDelete />
                            </button>
                          </div>
                        ) : (
                          <button
                            className="mt-2 py-1 px-3 bg-theme hover:bg-themeHover text-white rounded w-full"
                            onClick={() => addItemToCart(item)}
                          >
                            Add to Cart
                          </button>
                        )}
                        {/* <button
                          className="mt-2 py-1 px-3 bg-blue-500 text-white rounded w-full"
                          onClick={() => addItemToCart(item)}
                        >
                          Add to Cart
                        </button> */}
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-gray-500">
                    {selectedCategory
                      ? "No items found."
                      : "Select a category."}
                  </p>
                )}
              </div>
            </div>
          </div>
        </main>

        {/* Cart Overview and Summary */}
        <aside className="w-full sm:w-1/4 bg-gray-100 p-2 flex flex-col mt-4 sm:mt-0">
          <h2 className="text-lg font-semibold mb-4">Cart Overview</h2>
          <div className="flex-1 max-h-svh overflow-auto">
            {/* Cart Items */}
            {cartItems && cartItems.length > 0 ? (
              cartItems.map((item) => (
                <div
                  key={item.id}
                  className="flex gap-1 mb-4 border-b border-gray-300 pb-4"
                >
                  <div className="w-[60%]">
                    <h4 className="font-semibold">{item.name}</h4>
                    <p className="text-gray-500">Quantity: {item.quantity}</p>
                    <p className="text-gray-900 font-bold">
                      Price:{" "}
                      {formatCurrency(
                        item.pricings[0].price * item.quantity,
                        restaurant.currency
                      )}
                    </p>
                    <div className="flex space-x-2">
                      <button
                        className="py-1 px-3 bg-green-500 text-white rounded"
                        onClick={() => incrementItemQuantity(item)}
                      >
                        +
                      </button>
                      <button
                        className="py-1 px-3 bg-red-500 text-white rounded"
                        onClick={() => decrementItemQuantity(item)}
                      >
                        -
                      </button>
                      <button
                        className="py-1 px-3 bg-gray-500 text-white rounded"
                        onClick={() => removeItemFromCart(item)}
                      >
                        <MdDelete />
                      </button>
                    </div>
                  </div>
                  <div className="w-[40%]">
                    <textarea
                      className="w-full h-full p-2 border-2 text-xs"
                      placeholder="Remarks"
                      value={item.remarks}
                      maxLength={100}
                      onChange={(e) => updateItemRemarks(item, e.target.value)}
                    ></textarea>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-gray-500">No items in the cart.</p>
            )}
          </div>
          {/*Total Price*/}

          <div className="expandalbe-section">
            <div className="flex justify-between">
              <p className="text-xs text-gray-600">
                <strong>Item Total</strong>
              </p>
              <p className="text-gray-600 text-xs text-nowrap font-bold">
                {formatCurrency(totals.basePriceTotal, restaurant.currency)}
              </p>
            </div>
            {/* Discount section */}
            <p className="text-xs text-gray-500">Discount</p>
            <div className="flex justify-between">
              {/* input group with a select and input field */}
              <div className="flex flex-wrap items-center w-[70%]">
                <div className="relative">
                  <select
                    className="block border py-1 border-gray-300 shadow-sm focus:outline-none focus:ring focus:ring-opacity-50 w-[100px] text-xs"
                    value={discount.type}
                    onChange={(e) =>
                      setDiscount({ ...discount, type: e.target.value })
                    }
                  >
                    <option value="FIXED">Fixed</option>
                    <option value="PERCENTAGE">Percentage</option>
                  </select>
                </div>
                <div className="relative">
                  <input
                    type="number"
                    placeholder="Discount"
                    className="block w-[100px]  border border-gray-300 shadow-sm focus:outline-none focus:ring focus:ring-opacity-50 text-right px-1 py-[3px] text-xs"
                    value={discount.value}
                    onChange={(e) =>
                      setDiscount({ ...discount, value: e.target.value })
                    }
                  />
                </div>
              </div>
              <p className="text-gray-600 text-xs text-nowrap font-bold">
                -{formatCurrency(totals.discountAmount, restaurant.currency)}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-xs text-gray-600">
                <strong>Taxable</strong>
              </p>
              <p className="text-gray-600 text-xs text-nowrap font-bold">
                {formatCurrency(totals.taxableAmount, restaurant.currency)}
              </p>
            </div>
            {totals.taxAmount && totals.taxAmount > 0 && (
              <div className="flex justify-between">
                <p className="text-xs text-gray-600">
                  <strong>GST @ {restaurant?.taxRate}%</strong>
                </p>
                <p className="text-gray-600 text-xs text-nowrap font-bold">
                  {formatCurrency(totals.taxAmount, restaurant.currency)}
                </p>
              </div>
            )}
            <div className="flex justify-between">
              <p className="text-xs text-gray-600">
                <strong>Service Charge</strong>
              </p>
              <p className="text-gray-600 text-xs text-nowrap font-bold">
                {formatCurrency(
                  totals.serviceChargeAmount,
                  restaurant.currency
                )}
              </p>
            </div>
            {totals?.additionalCharges && totals?.additionalCharges !== 0 && (
              <div className="flex justify-between">
                <p className="text-xs text-gray-600">
                  <strong>Additional Charges</strong>
                </p>
                <p className="text-gray-600 text-xs text-nowrap font-bold">
                  {formatCurrency(
                    totals.additionalCharges,
                    restaurant.currency
                  )}
                </p>
              </div>
            )}
            {totals.roundOff !== 0 && (
              <div className="flex justify-between">
                <p className="text-xs text-gray-600">
                  <strong>Round Off</strong>
                </p>
                <p className="text-gray-600 text-xs text-nowrap font-bold">
                  {formatCurrency(totals.roundOff, restaurant.currency)}
                </p>
              </div>
            )}
            <div className="flex justify-between">
              <p className="text-sm font-bold">
                <strong>Grand Total</strong>
              </p>
              <p className="text-gray-900 text-nowrap font-bold">
                {formatCurrency(totals.totalAmount, restaurant.currency)}
              </p>
            </div>
          </div>

          <div className="mt-4 flex space-x-0">
            <button
              className="py-2 px-1 bg-success hover:bg-successHover text-themeText  flex-1 text-xs"
              onClick={() => cartItems.length > 0 && setFinalReviewModal(true)}
            >
              {restaurant?.paymentFlow === "PRE" ? "Pay Now" : "Place Order"}
            </button>
            <button
              className="py-2 px-1 bg-secondary hover:bg-secondaryHover text-white  flex-1 text-xs"
              onClick={() => saveDraft(activeDraftId)}
            >
              Save Draft
            </button>
            {activeDraftId && (
              <button
                className="py-2 px-1 bg-delete hover:bg-deleteHover text-white  flex-1 text-xs"
                onClick={() => deleteDraft(activeDraftId)}
              >
                Delete Draft
              </button>
            )}
            <button
              className="py-2 px-1  bg-danger hover:bg-dangerHover text-white  flex-1 text-xs"
              onClick={() => {
                resetOrderData();
              }}
            >
              Cancel
            </button>
          </div>
        </aside>
        {orderBar && (
          <aside className="hidden sm:block w-1/4 bg-gray-100 p-2 border-l-4">
            <h2 className="text-lg font-semibold mb-4">Recent Orders</h2>
            <div className="flex-1 overflow-auto max-h-svh">
              <PosOrderList restaurantId={restaurantId} ref={orderListRef} />
            </div>
          </aside>
        )}
      </div>

      {/* Back Button */}
      <div className="absolute bottom-4 left-4 hidden md:block">
        <Link to={`/owner/restaurant/${restaurantId}`}>
          <button className="py-2 px-4 bg-warning hover:bg-warningHover text-white rounded">
            Exit POS
          </button>
        </Link>
        {draftIds &&
          draftIds.length > 0 &&
          draftIds.map((draft) => {
            const updatedTime = moment(draft.updatedAt);
            const now = moment();
            let timeLabel;

            if (now.diff(updatedTime, "days") >= 1) {
              timeLabel = updatedTime.format("Do MMM | HH:mm"); // Show specific date for older drafts
            } else {
              timeLabel = updatedTime.fromNow(); // Show relative time for recent drafts
            }

            return (
              <button
                key={draft.draftId}
                className="py-2 px-4 bg-blue-500 hover:bg-blue-700 text-white rounded ml-2 disabled:bg-gray-600 disabled:text-white"
                onClick={() => setActiveDraftId(draft.draftId)}
                disabled={activeDraftId === draft.draftId}
              >
                {timeLabel}
              </button>
            );
          })}
      </div>

      {/* Modal for Order Search */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-4 rounded">
            <button onClick={closeModal} className="float-right">
              Close
            </button>
            <input
              type="text"
              placeholder="Search by Order Number"
              className="border border-gray-300 rounded p-2 w-full"
            />
          </div>
        </div>
      )}
      {finalReviewModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center ">
          <div
            className={`bg-white border-4 border-${restaurant.orderTypes[orderType].metadata.buttonColor}-500 w-full sm:w-1/3 p-4 rounded max-h-screen overflow-auto`}
          >
            <div className="flex justify-between border-b border-gray-300">
              <p className="text-xl font-semibold mb-2">Final Review</p>
              <button onClick={() => setFinalReviewModal(false)}>
                <MdClose
                  className="text-red-600 hover:text-red-800"
                  size={24}
                />
              </button>
            </div>
            <div className="text-xs text-gray-500 mb-4 pt-2">
              <p className="">
                <strong>Mobile Number :</strong>{" "}
                <input
                  type="text"
                  value={mobile}
                  placeholder="Not Provided"
                  onChange={handleMobile}
                  className={`p-1 border-2 ${
                    mobileError === "" ? "" : "border-red-500"
                  }`}
                  maxLength={10}
                />
              </p>
              <p className="text-xs text-red-600">{mobileError}</p>

              {orderType === "DINE-IN" && (
                <p className="">
                  <strong>Table Number :</strong>{" "}
                  {tableNumber || "not selected"}
                </p>
              )}
              {metaData &&
                metaData.map((data, index) => (
                  <p>
                    <strong>{data.dataName} :</strong> {data.dataValue}
                  </p>
                ))}
            </div>

            {/* Cart Items */}
            {cartItems.map((item) => (
              <div
                key={item.id}
                className="border-b border-gray-300 pb-4 flex justify-between"
              >
                <div>
                  <p className="text-gray-800 text-sm">
                    <strong>{item.name}</strong>
                  </p>
                  <p className="text-gray-500 text-xs">
                    <strong>Quantity: </strong>
                    {item.quantity}
                  </p>
                  {item.remarks && (
                    <p className="text-gray-500 text-xs">
                      <strong>Remarks: </strong>
                      {item.remarks}
                    </p>
                  )}
                </div>
                <p className="text-gray-800 text-sm">
                  <strong>
                    {formatCurrency(
                      item.pricings[0].price * item.quantity,
                      restaurant.currency
                    )}
                  </strong>
                </p>
              </div>
            ))}

            <div className="flex justify-between">
              <p className="text-sm font-semibold">
                Final Total{" "}
                <span className="text-xs text-gray-500">
                  (Including GST & Other charges)
                </span>
              </p>
              <p className="text-gray-900 text-nowrap font-bold">
                {formatCurrency(totals.totalAmount, restaurant.currency)}
              </p>
            </div>
            {restaurant?.paymentFlow === "PRE" && (
              <PaymentComponent amount={totals.totalAmount} ref={payNowRef} />
            )}
            <div className="flex justify-end">
              <button
                className={`py-2 px-4 bg-${restaurant.orderTypes[orderType].metadata.buttonColor}-500 shadow-sm hover:shadow-md text-white  flex-1 text-xs`}
                onClick={() => {
                  placeOrder();
                }}
              >
                {restaurant?.paymentFlow === "PRE" ? "Pay Now" : "Place Order"}
              </button>
            </div>
          </div>
        </div>
      )}

      {loading && <Loading />}
      <OrderDetailModal
        restaurantId={restaurantId}
        orderId={selectedOrderId}
        isOpen={isViewModalOpen}
        closeModal={closeViewModal}
      />
    </div>
  );
};

export default Pos;
