import React, { useEffect } from "react";

import { App as CapApp } from "@capacitor/app";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { CityProvider } from "./CityContext";
import { OwnerProvider } from "./context/OwnerContext";
import Root from "./pages/routes/root";
import NotFoundPage, { Error500 } from "./components/ErrorPage";
import Owner from "./pages/Owner";
import AddRestaurant from "./componentsOwner/AddRestaurant";
import Login from "./componentsOwner/Login";
import MenuCategories from "./componentsOwner/MenuCategories";
import RestaurentDetails from "./componentsOwner/RestaurentDetails";
import FoodMenuItems from "./pages/owner/FoodMenuItems";
import TableMaster from "./pages/owner/TableMaster";
import Pos from "./pages/owner/Pos";
import Orders from "./pages/owner/Orders";
import Kitchen from "./pages/owner/Kitchen";
import InvoicePage from "./pages/owner/InvoicePage";
import Registration from "./componentsOwner/Registration";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <Error500 />,

    children: [
      { index: true, element: <Navigate to="/owner/owner-home" replace /> },
      {
        path: "owner/owner-home",
        element: <Owner />,
      },
      {
        path: "owner/add-restaurant",
        element: <AddRestaurant />,
      },
      {
        path: "owner/menu-categories",
        element: <MenuCategories />,
      },
      {
        path: "owner/restaurant/:restaurantId",
        element: <RestaurentDetails />,
      },
      {
        path: "owner/restaurant/:restaurantId/food-menu",
        element: <FoodMenuItems />,
      },
      {
        path: "owner/restaurant/:restaurantId/tables",
        element: <TableMaster />,
      },

      {
        path: "owner/restaurant/:restaurantId/orders",
        element: <Orders />,
      },
      {
        path: "owner/restaurant/:restaurantId/Kitchen",
        element: <Kitchen />,
      },
      {
        path: "owner/restaurant/:restaurantId/invoice:orderId",
        element: <InvoicePage />,
      },
    ],
  },
  {
    path: "owner/restaurant/:restaurantId/pos",
    element: <Pos />,
  },
  {
    path: "/owner/owner-login",
    element: <Login />,
  },
  {
    path: "/owner/owner-registration",
    element: <Registration />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);
const App = () => {
  // const navigate = useNavigate();
  useEffect(() => {
    const backButtonListener = CapApp.addListener(
      "backButton",
      ({ canGoBack }) => {
        if (canGoBack) {
          window.history.back();
        } else {
          CapApp.exitApp(); // Close the app if there's no history to go back to
        }
        // if (window.location.pathname !== "/owner/owner-login") {
        //   window.history.back(); // Go back in history
        // } else {
        //   CapApp.exitApp(); // Close the app if on the login page
        // }
      }
    );

    return () => {
      backButtonListener.remove(); // Clean up the listener when the component is unmounted
    };
  }, []);

  return (
    <CityProvider>
      <OwnerProvider>
        <RouterProvider router={router} />
      </OwnerProvider>
    </CityProvider>
  );
};

export default App;
