// OwnerContext.js
import { createContext, useContext } from "react";

// Create the context
export const ResturantContext = createContext();

// Custom hook to use the OwnerContext
export const useResturantContext = () => {
  return useContext(ResturantContext);
};
