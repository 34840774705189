import React from 'react';

const MenuItemsTable = ({ menuItems=[],showCategory, showAction,renderAction }) => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-300 table-auto">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">Item Name</th>
            <th className="py-2 px-4 border-b">Description</th>
            <th className="py-2 px-4 border-b">Available</th>
            <th className="py-2 px-4 border-b">Price</th>
            {showCategory && <th className="py-2 px-4 border-b">Category</th>}
            <th className="py-2 px-4 border-b">Restaurant</th>
            {showAction && renderAction &&  <th className="py-2 px-4 border-b">Action</th>}
          </tr>
        </thead>
        <tbody>
          {menuItems.map((item, index) => (
            <tr key={index}>
              <td className="py-2 px-4 border-b">{item.name}</td>
              <td className="py-2 px-4 border-b">{item.description}</td>
              <td className="py-2 px-4 border-b">{item.isAvailable ? 'Yes' : 'No'}</td>
              <td className="py-2 px-4 border-b">{item.price || (item.pricings.length > 0 ?item.pricings[0].price : 'N/A')}</td>
              {showCategory && <td className="py-2 px-4 border-b">{item.category?.name || 'N/A'}</td>}
              <td className="py-2 px-4 border-b">{item.restaurant?.name || 'N/A'}</td>
              {showAction && renderAction && (
                <td className="py-2 px-4 border-b">
                   {renderAction(item)}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MenuItemsTable;
