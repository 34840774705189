import React, { useCallback, useEffect, useState } from 'react';
import { BASE_URL } from '../utils/services';
import Loading from "../components/Loading";
import MenuItemsTable from './MenuItemsTable';

export default function CategoryCrud({ onClose, data,action="detail" }) {
  const [error, setError] = useState(null);
  const [category, setCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    id:''
  });

  let name, value;
  const handleChange = (e) => {
    name = e.target.name;
    value = e.target.value;

    setFormData({ ...formData, [name]: value });
  };
  const fetchCategory = useCallback( async () => {
    const params = new URLSearchParams({
      includeMenuItems: action === 'detail',
      includeRestaurants: action === 'detail'
    });

const url = `${BASE_URL}/owner/menu-category/${data}?${params.toString()}`;
    const res = await fetch(url, {
      method: "GET",
     
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
    });
    const cat = await res.json();
    setCategory(cat.category);
    if(action==='edit')
    {
      setFormData({
        name: cat.category.name,
        description: cat.category.description,
        id: cat.category.id
      })
    }
  },[data,action])
  const addCategory = async () => {
    const { name, description } = formData;
    const res = await fetch(`${BASE_URL}/owner/add-menu-category`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          name, description
        }),
    });
    return res;
  }
  const editCategory = async () => {
    const { name, description } = formData;
    const res = await fetch(`${BASE_URL}/owner/menu-category/${data}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          name, description
        }),
    });
    return res;
  }

  const handleEdit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      let res;
      if (action === 'create')
      {

         res = await addCategory();
      }
      else if (action === 'edit')
      {
         res = await editCategory();
      }
      const data = await res.json();
      // console.log(data);

      if (res.status === 422 || !data) {
        window.alert("All Fields are Mandatory.");
      } else if (res.status === 404) {
        window.alert("User not found.");
      } else if (res.status === 500) {
        window.alert("Internal server error.");
      } else if (res.status === 200) {
        window.alert(action ==="create" ?"New category created successfully.":`Category - ${category.name} updated successfully.`);
        onClose();
      } else {
        window.alert(res.json);
      }
    } catch (error) {
      window.alert("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    
    if (action !== 'create' && data) {
      fetchCategory()
    }
  }, [action, data,fetchCategory])

  return (
    <div className="fixed flex flex-col justify-center items-center top-0 left-0 w-full h-full bg-filterFloat z-20">
      <div className="flex flex-col justify-center items-center gap-6 bg-white p-12 shadow-review">
        {error && <p className="text-red-500">{error}</p>}
        <div className='flex flex-col justify-center items-center'>

        <p className='text-md font-bold'>{action === 'create' ? 'New Category' : (!category ? 'Loading...' : `Category : ${category?.name}`)}</p>

        {action !== 'create' && <p className='text-sm'>{category?.description}</p>}
        </div>
        {action !== 'detail' &&
        <form className='flex flex-col items-center gap-4' onSubmit={(e) => handleEdit(e)}>
          <input className="w-[280px] p-4 bg-bg outline-none" placeholder='Category Name' type="text" name="name" value={formData.name} onChange={handleChange} required />
          <textarea className="w-[280px] p-4 bg-bg outline-none" name="description" placeholder='Description' value={formData.description} onChange={handleChange} required />
          
          <button className='w-[280px] p-3 bg-theme text-white font-bold text-xl hover:opacity-80' type="submit">{loading ? "Saving..." : "Save"}</button>
        </form>
        }
        {action === 'detail' && category && category?.menuItems?.length > 0 && <MenuItemsTable menuItems={category.menuItems} showAction={false} />}
      </div>
      <div className='flex justify-center items-center mt-3 bg-border h-10 w-10 rounded-full text-white text-3xl cursor-pointer' onClick={onClose}>×</div>
      {loading && <Loading />}
    </div>
  );
}
