// OwnerContext.js
import { createContext, useContext } from "react";

// Create the context
export const MyThemeContext = createContext();

// Custom hook to use the OwnerContext
export const useThemeContext = () => {
  return useContext(MyThemeContext);
};
