import React, { useCallback, useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import PageLoading from "../../componentsOwner/PageLoading";

import {
  MdArrowBack,
  MdDelete,
  MdEdit,
  MdToggleOn,
  MdToggleOff,
} from "react-icons/md";
import { AiFillHome } from "react-icons/ai";
import { BASE_URL } from "../../utils/services";

import Loading from "../../components/Loading";
import FloatingAddButton from "../../components/FloatingAddButton";
import FoodMenuCrud from "../../componentsOwner/FoodMenuCrud";
import ServerSideTable from "../../components/ServerSideTable ";
import { foodType } from "../../utils/helper";
import { useThemeContext } from "../../context/ThemeContext";
import { useResturantContext } from "../../context/ResturantContext";

const FoodMenuItems = () => {
  const { restaurant } = useResturantContext();

  const { restaurantId } = useParams();
  const { isDesktop } = useThemeContext();
  console.log(isDesktop);
  // const [restaurant, setRestaurant] = useState(null);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [crudData, setCrudData] = useState(null);

  const [loading, setLoading] = useState(false);

  const [refresh, setRefresh] = useState(0);
  const renderAction = (item) => {
    return (
      <div className="flex gap-2">
        {item.isAvailable ? (
          <MdToggleOn
            onClick={() => toggleAvailability(item.id)}
            className="text-xl cursor-pointer text-available hover:text-availableHover"
          />
        ) : (
          <MdToggleOff
            onClick={() => toggleAvailability(item.id)}
            className="text-xl cursor-pointer text-unavailable hover:text-unavailableHover"
          />
        )}
        <MdEdit
          onClick={() => handleCrudModal(item.id, "edit")}
          className="text-xl mr-2 cursor-pointer text-edit hover:text-editHover"
        />
        <MdDelete
          onClick={() => handleCrudModal(item.id, "delete")}
          className="text-xl cursor-pointer text-delete hover:text-deleteHover"
        />
      </div>
    );
  };

  const toggleAvailability = async (id) => {
    try {
      const res = await fetch(
        `${BASE_URL}/owner/restaurant/${restaurantId}/menu-item/${id}/toggle-availability`,
        {
          method: "PUT",
          credentials: "include",
        }
      );

      if (res.status === 200) {
        triggerRefresh();
      } else {
        throw new Error(res.json);
        // console.error('Failed to toggle availability');
      }
    } catch (error) {
      setError("Failed to toggle availability");
      console.error("Error toggling availability:", error);
    }
  };

  const fetchMenuItems = useCallback(
    async ({ pageIndex, pageSize, sortBy, filters }) => {
      try {
        setLoading(true);
        const res = await fetch(
          `${BASE_URL}/owner/restaurant/${restaurantId}/menu-items`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
              pageIndex,
              pageSize,
              sortBy,
              filters,
            }),
          }
        );
        const data = await res.json();

        if (res.status === 200) {
          // setRestaurant(data.restaurant);
          return {
            data: data.restaurant.menuItems,
            pageCount: data.pageCount,
          };
        } else if (res.status === 403) {
          window.alert("Unauthorized Access.");
        } else {
          console.error("Failed to fetch restaurant details");
        }
      } catch (error) {
        console.error("Error fetching restaurant details:", error);
      } finally {
        setLoading(false);
      }
    },
    [restaurantId]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value, row }) => (
          <div className="flex items-center">
            {value}
            {foodType(row.original.itemType)}
          </div>
        ),
        // Filter example
        Filter: ({ column }) => (
          <input
            value={column.filterValue || ""}
            onChange={(e) => column.setFilter(e.target.value || undefined)}
            placeholder="Search name"
            className="p-2 bg-bg outline-none focus:outline-black"
          />
        ),
      },
      {
        Header: "Description",
        accessor: "description",
        isVisible: false,
        Filter: ({ column }) => (
          <input
            value={column.filterValue || ""}
            onChange={(e) => column.setFilter(e.target.value || undefined)}
            placeholder="Search description"
            className="p-2 bg-bg outline-none focus:outline-black"
          />
        ),
      },
      {
        Header: "Type",
        accessor: "itemType",

        Filter: ({ column }) => (
          <select
            value={column.filterValue || ""}
            onChange={(e) => column.setFilter(e.target.value || undefined)}
            className="p-2 bg-bg outline-none focus:outline-black"
          >
            <option value="">All</option>
            <option value="EGG-ONLY">Egg-Only</option>
            <option value="NON-VEG">Non-Veg</option>
            <option value="PURE-VEG">Pure-Veg</option>
          </select>
        ),
      },
      {
        Header: "Available",
        accessor: "isAvailable",
        Cell: ({ value }) => (value ? "Yes" : "No"),
        Filter: ({ column }) => (
          <select
            value={column.filterValue || ""}
            onChange={(e) => column.setFilter(e.target.value || undefined)}
            className="p-2 bg-bg outline-none focus:outline-black"
          >
            <option value="">All</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        ),
      },
      {
        Header: "Category",
        accessor: "category.name",
        Filter: ({ column }) => (
          <input
            value={column.filterValue || ""}
            onChange={(e) => column.setFilter(e.target.value || undefined)}
            placeholder="Search category"
            className="p-2 bg-bg outline-none focus:outline-black"
          />
        ),
      },

      {
        Header: "Price",
        accessor: (row) => row.pricings[0]?.price,
        // Filter: ({ column }) => (
        //   <input
        //     value={column.filterValue || ""}
        //     onChange={(e) => column.setFilter(e.target.value || undefined)}
        //     placeholder="Search price"
        //     className="p-2 bg-bg outline-none focus:outline-black"
        //     type="number"
        //   />
        // ),
        disableSortBy: true,
      },
      {
        Header: "Actions",
        accessor: (row) => renderAction(row),
        disableSortBy: true,
        //   Cell: ({ row }) => renderAction(row.original),
      },
    ],
    []
  );

  const triggerRefresh = () => {
    setRefresh((prev) => prev + 1);
  };

  // useEffect(() => {
  //   const fetchRestaurant = async () => {
  //     const res = await fetch(`${BASE_URL}/owner/restaurant/${restaurantId}`, {
  //       method: "GET",
  //       credentials: "include",
  //     });
  //     const data = await res.json();
  //     if (res.status === 200) {
  //       setRestaurant(data.restaurant);
  //       setRestaurantData(data.restaurant);
  //     } else if (res.status === 403) {
  //       window.alert("Unauthorized Access.");
  //     } else {
  //       setError("Failed to fetch restaurant details");
  //     }
  //   };
  //   fetchRestaurant();
  // }, [restaurantId]);

  const handleCrudModal = (id, action = "create") => {
    // e.preventDefault();

    setCrudData({ action, data: action === "create" ? null : id });
    setShowModal(true);
  };

  if (!restaurant) {
    return (
      <>
        <PageLoading link={"/owner/owner-home"} />
      </>
    );
  }

  return (
    <>
      <div className="flex flex-col justify-center items-center ">
        <div>
          {error && (
            <p className="text-red-500">
              {error}
              <br />
              <Link to="/owner/owner-home" className="icon" title="Back Home">
                <AiFillHome size={25} />
              </Link>
            </p>
          )}
        </div>

        {loading && <Loading />}
        <div className="flex justify-start items-center bg-white  w-full p-4 mb-2 rounded-lg max-w-6xl">
          <Link
            to={`/owner/restaurant/${restaurantId}`}
            className=" text-xs inline-flex items-center"
          >
            <MdArrowBack size={20} className="mr-2" />{" "}
            <span className="hidden sm:block">Back</span>
          </Link>
          <p className="text-xs sm:text-sm font-bold sm:ml-2">Food Menu</p>
        </div>
        <div className="w-full bg-white max-w-6xl rounded-lg shadow-md">
          <ServerSideTable
            columns={columns}
            fetchData={fetchMenuItems}
            refresh={refresh} // Pass the refresh state as a prop
          />
          {/* <MenuItemsTable menuItems={restaurant?.menuItems} showAction={true}showCategory={true} renderAction={renderAction}/> */}
        </div>
      </div>
      {showModal && (
        <FoodMenuCrud
          restaurantId={restaurantId}
          action={crudData?.action}
          data={crudData?.data}
          onClose={() => {
            setShowModal(false);
            setCrudData(null);
            triggerRefresh();
            // fetchMenuItems();
          }}
        />
      )}
      <FloatingAddButton
        title={"New Food menu item"}
        onClick={handleCrudModal}
      />
    </>
  );
};

export default FoodMenuItems;
