import React from "react";

const RestaurantTable = ({ tables = [] }) => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-300 table-auto">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">Table Number</th>
            <th className="py-2 px-4 border-b">Capacity</th>
            <th className="py-2 px-4 border-b">Status</th>
          </tr>
        </thead>
        <tbody>
          {tables.map((item, index) => (
            <tr key={index}>
              <td className="py-2 px-4 border-b">{item.tableNumber}</td>
              <td className="py-2 px-4 border-b">{item.capacity}</td>
              <td className="py-2 px-4 border-b">{item.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RestaurantTable;
