import React from 'react';
import {BallTriangle} from 'react-loader-spinner';
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const Loading = () => {
    return (
        <div className="fixed flex justify-center items-center top-0 left-0 w-full h-full bg-filterFloat z-50">
            {/* <Loader
                type="Oval"
                color="#FF5757"
                height={70}
                width={70}
            /> */}
            <BallTriangle
  height={100}
  width={100}
  radius={5}
  color="#4fa94d"
  ariaLabel="ball-triangle-loading"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
  />
        </div>
    )
}

export default Loading;
