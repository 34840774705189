import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import LogoutButton from "../../componentsOwner/LogoutButton";
import { version } from "../../version";
import { BASE_URL } from "../../utils/services";
import { MdHelpCenter, MdNotifications, MdOutlineMenu } from "react-icons/md";
import { useState, useEffect, useRef } from "react";
import { useOwner } from "../../context/OwnerContext";
import { MyThemeContext } from "../../context/ThemeContext";
import { ResturantContext } from "../../context/ResturantContext";
import { getInitials } from "../../utils/helper";

import logo from "../../assets/logo-160.png";
export default function Root() {
  const navigate = useNavigate();
  const { restaurantId } = useParams();

  const { owner, loginOwner, logoutOwner } = useOwner();
  // Utility function to check if the screen is wide (e.g., desktop)

  // Initialize state based on screen size
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [restaurant, setRestaurant] = useState(null);
  const isDesktop = () => window.matchMedia("(min-width: 1024px)").matches;
  const [isMenuOpen, setIsMenuOpen] = useState(isDesktop());
  const location = useLocation(); // React Router hook to track location
  const sidebarRef = useRef(null); // Create a reference to the sidebar
  const toggleButtonRef = useRef(null); // Reference to the toggle button
  useEffect(() => {
    const handleResize = () => {
      setIsMenuOpen(isDesktop());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!isDesktop()) {
      setIsMenuOpen(false); // Close sidebar on mobile when the route changes
    }
  }, [location]); // Track route changes

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuClick = () => {
    if (!isDesktop()) {
      setIsMenuOpen(false); // Close sidebar when clicking on a menu item on mobile
    }
  };
  // Function to close sidebar when clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        !toggleButtonRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false); // Close sidebar if clicked outside on mobile
      }
    };

    if (!isDesktop()) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // Cleanup
    };
  }, [isMenuOpen]);
  useEffect(() => {
    if (restaurantId) {
      const fetchRestaurantDetails = async () => {
        try {
          const res = await fetch(
            `${BASE_URL}/owner/restaurant/${restaurantId}`,
            {
              method: "GET",
              credentials: "include",
            }
          );
          const data = await res.json();
          // console.log(data);
          if (res.status === 200) {
            setRestaurant({ ...data.restaurant, orderTypes: data.ORDER_TYPES });
          } else if (res.status === 403) {
            window.alert("Unauthorized Access.");
          } else {
            console.error("Failed to fetch restaurant details");
          }
        } catch (error) {
          console.error("Error fetching restaurant details:", error);
        }
      };

      fetchRestaurantDetails();
    } else {
      setRestaurant(null);
    }
  }, [restaurantId]);

  const callHomePage = async () => {
    setLoading(true);
    try {
      const res = await fetch(`${BASE_URL}/owner/owner-home`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      const data = await res.json();
      // console.log(data);

      if (res.status === 401) {
        navigate("/owner/owner-login");
      }

      if (!res.status === 200) {
        const error = new Error(res.error);
        throw error;
      }
      if (data) {
        console.log(data);
        setUserData(data);
        loginOwner(data);
      }
    } catch (error) {
      console.log(error);
      navigate("/owner/owner-login");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!userData) {
      callHomePage();
    }
  });
  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    );
  }
  return (
    <MyThemeContext.Provider
      value={{ isDesktop: isDesktop(), setIsMenuOpen, isMenuOpen }}
    >
      <ResturantContext.Provider value={{ restaurant, setRestaurant }}>
        <div className="h-screen flex flex-col">
          <div className="flex flex-none h-14 p-2 sm:p-4">
            <div className="inline-flex gap-1 items-center flex-initial w-1/4">
              <button
                type="button"
                ref={toggleButtonRef}
                onClick={toggleMenu}
                className="mr-2 cursor-pointer text-theme"
              >
                <MdOutlineMenu size={36} />
              </button>
              <Link
                className="flex items-center justify-center gap-2"
                to={`/owner/account-center`}
              >
                <div className="w-9 h-9 bg-theme rounded-full flex items-center justify-center text-white text-lg font-semibold">
                  {owner && getInitials(owner?.fullName)}
                </div>
                <strong className="hidden sm:block">{owner?.fullName}</strong>
              </Link>
              {/* <p className="text-xs sm:text-sm font-bold sm:ml-2">Home</p> */}
            </div>
            <div className="flex-1 flex justify-center items-center">
              <p className="text-lg sm:text-xl font-semibold  text-center">
                {restaurant ? restaurant.name : <img src={logo} alt="yuMmy" />}
              </p>
            </div>
            <div className="w-1/4 flex justify-end gap-2 items-center">
              <MdNotifications size={36} className="text-theme" />
              <MdHelpCenter size={36} className="text-theme" />
            </div>
          </div>
          <div className="flex flex-grow">
            {isMenuOpen && (
              <div
                className="flex flex-col w-11/12 sm:w-64 bg-gray-200 sidebar"
                ref={sidebarRef}
              >
                <div className="flex-none p-2 bg-theme/90 text-white">
                  <Link
                    to={`/owner/profile`}
                    className="text-center block text-wrap text-md sm:text-xl font-semibold "
                  >
                    {userData?.username}
                  </Link>
                </div>
                <nav className="flex-grow overflow-y-auto custom-height-sidebar text-md">
                  <ul className="flex flex-col w-full divide-y-2 divide-gray-300 [&>li]:w-full [&>li]:p-2 [&>li]:text-gray-800 [&>li]:font-semibold">
                    <li
                      className="hover:bg-theme/50 hover:text-white "
                      onClick={handleMenuClick}
                    >
                      <Link to={`/owner/owner-home`} className=" block">
                        Restaurants
                      </Link>
                    </li>
                    <li
                      className=" hover:bg-theme/50 hover:text-white "
                      onClick={handleMenuClick}
                    >
                      <Link className="block" to={"/owner/menu-categories"}>
                        Food Categories
                      </Link>
                    </li>
                    {restaurantId && (
                      <li className="hover:bg-theme/50 hover:text-white group focus-within:bg-theme/50">
                        <button className="w-full text-left flex items-center justify-between peer focus:outline-none">
                          <span>Restaurant Options</span>
                          <span className="caret">▼</span> {/* Caret sign */}
                        </button>
                        <ul className="pl-4 mt-1 space-y-1 hidden group-hover:block peer-focus:block">
                          <li
                            className="hover:bg-theme/50 hover:text-white p-2"
                            onClick={handleMenuClick}
                          >
                            <Link
                              title="Food Menu"
                              to={`/owner/restaurant/${restaurantId}/food-menu`}
                              className="block"
                            >
                              Food Menu
                            </Link>
                          </li>
                          <li
                            className="hover:bg-theme/50 hover:text-white p-2"
                            onClick={handleMenuClick}
                          >
                            <Link
                              title="Food Menu"
                              to={`/owner/restaurant/${restaurantId}/tables`}
                              className="block"
                            >
                              Tables
                            </Link>
                          </li>
                          <li
                            className="hover:bg-theme/50 hover:text-white p-2"
                            onClick={handleMenuClick}
                          >
                            <Link
                              title="Food Menu"
                              to={`/owner/restaurant/${restaurantId}/pos`}
                              className="block"
                            >
                              POS
                            </Link>
                          </li>
                          <li className="hover:bg-theme/50 hover:text-white p-2">
                            <Link
                              title="Food Menu"
                              to={`/owner/restaurant/${restaurantId}/orders`}
                              className="block"
                            >
                              Orders
                            </Link>
                          </li>
                          <li className="hover:bg-theme/50 hover:text-white p-2">
                            <Link
                              title="Food Menu"
                              to={`/owner/restaurant/${restaurantId}/kitchen`}
                              className="block"
                            >
                              Kitchen
                            </Link>
                          </li>
                        </ul>
                      </li>
                    )}
                    {/* Menu item with submenu */}
                    <li className="hover:bg-theme/50 hover:text-white group focus-within:bg-theme/50">
                      <button className="w-full text-left flex items-center justify-between peer focus:outline-none">
                        <span>POS</span>
                        <span className="caret">▼</span> {/* Caret sign */}
                      </button>
                      <ul className="pl-4 mt-1 space-y-1 hidden group-hover:block peer-focus:block">
                        {userData?.restaurants &&
                          userData.restaurants.map((restaurant) => (
                            <li
                              key={restaurant.id}
                              className="hover:bg-theme/50 hover:text-white p-2"
                            >
                              <Link
                                to={`/owner/restaurant/${restaurant.id}/pos`}
                                className="block"
                              >
                                {restaurant.name}
                              </Link>
                            </li>
                          ))}
                      </ul>
                    </li>
                    <li className=" hover:bg-theme/50 hover:text-white ">
                      <LogoutButton
                        userData={userData}
                        handleUserData={() => {
                          setUserData(null);
                          logoutOwner();
                        }}
                      />
                    </li>
                    <li className=" hover:bg-theme/50 hover:text-white ">
                      <LogoutButton
                        userData={userData}
                        handleUserData={() => {
                          setUserData(null);
                          logoutOwner();
                        }}
                        all
                      />
                    </li>
                  </ul>
                </nav>
                <div className="flex-none p-2 bg-theme/90 text-white">
                  <p className="text-center text-sm  font-bold">YuMmy</p>
                  <p className="text-center text-xs  font-semibold">
                    {version}
                  </p>
                </div>
              </div>
            )}
            <div className="flex-grow w-1/2 bg-gray-100 p-2 sm:p-4 custom-height-content overflow-auto">
              {" "}
              <Outlet />
            </div>
          </div>
        </div>
      </ResturantContext.Provider>
    </MyThemeContext.Provider>
  );
}
