import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../utils/services";
import { Link, useParams } from "react-router-dom";
import PageLoading from "../../componentsOwner/PageLoading";
import { AiFillHome } from "react-icons/ai";
import { MdArrowBack } from "react-icons/md";
import Loading from "../../components/Loading";
import pluralize from "pluralize";
import { useResturantContext } from "../../context/ResturantContext";

export default function Kitchen() {
  const { restaurantId } = useParams();
  const { restaurant } = useResturantContext();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState(null);

  useEffect(() => {
    if (restaurant) {
      const fetchKitchenOrders = async () => {
        try {
          setLoading(true);
          const res = await fetch(`${BASE_URL}/owner/kitchen/${restaurantId}`, {
            method: "GET",
            credentials: "include",
          });
          const data = await res.json();
          if (res.status === 200) {
            setOrders(data);
          } else if (res.status === 403) {
            window.alert("Unauthorized Access.");
          } else {
            setError("Failed to fetch restaurant details");
          }
        } catch (error) {
          setError("Failed to fetch kitchen orders.");
          console.log(error);
        } finally {
          setLoading(false);
        }
      };
      fetchKitchenOrders();
    }
  }, [restaurant, restaurantId]);
  if (!restaurant) {
    return (
      <>
        <PageLoading link={"/owner/owner-home"} />
      </>
    );
  }

  return (
    <>
      <div className="flex flex-col justify-center items-center">
        <div>
          {error && (
            <p className="text-red-500 items-center inline-flex">
              {error}

              <Link
                to="/owner/owner-home"
                className="icon inline-block"
                title="Back Home"
              >
                <AiFillHome size={25} />
              </Link>
            </p>
          )}
        </div>
        <div className="flex justify-start items-center bg-white  w-full p-4 mb-2 rounded-lg max-w-6xl">
          <Link
            to={`/owner/restaurant/${restaurantId}`}
            className=" text-xs inline-flex items-center"
          >
            <MdArrowBack size={20} className="mr-2" />{" "}
            <span className="hidden sm:block">Back</span>
          </Link>
          <p className="text-xs sm:text-sm font-bold sm:ml-2">Kitchen</p>
        </div>

        <div className="w-full">
          <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-6 gap-2">
            {orders &&
              orders.map((order) => (
                <div
                  key={order.id}
                  className="flex flex-col justify-between border p-2 rounded border-gray-300"
                >
                  <div className="border-b flex justify-between">
                    <p className="text-md">#{order.id}</p>
                    <p className="text-md text-gray-700">{order.orderType}</p>
                  </div>
                  <div className="p-1 text-xs max-h-30 overflow-auto justify-start">
                    {order.orderItems.map((item) => (
                      <div key={item.id} className="flex justify-between">
                        <p>
                          {pluralize(item.menuItem.name, item.quantity, true)}
                        </p>
                        <p></p>
                      </div>
                    ))}
                  </div>
                  <div className="flex mt-auto">
                    <button className="bg-green-500 text-white px-2 py-1 rounded mr-2">
                      Ready
                    </button>
                    <button className="bg-red-500 text-white px-2 py-1 rounded">
                      Cancel
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>

        {loading && <Loading />}
      </div>
    </>
  );
}
