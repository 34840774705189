import { forwardRef, useImperativeHandle, useState } from "react";

const PaymentComponent = forwardRef(function PaymentComponent({ amount }, ref) {
  const [payingNow, setPayingNow] = useState({
    amount: isNaN(amount) ? 0 : parseFloat(amount),
    paymentMethod: "Cash",
    transactionId: "",
  });

  useImperativeHandle(ref, () => ({
    getPayingNow() {
      return payingNow;
    },
  }));

  return (
    <div className="my-4 flex flex-col gap-2">
      <div className="flex justify-between">
        <p className="text-sm font-semibold">Paying Now</p>
        <input
          type="number"
          value={payingNow.amount}
          className="text-right font-bold w-[30%] border-2 border-grey-500 px-2"
          autoFocus
          max={payingNow.amount}
          min={0}
          placeholder={amount}
          onChange={(e) =>
            setPayingNow({ ...payingNow, amount: parseFloat(e.target.value) })
          }
        />
      </div>

      <div className="flex justify-between">
        <p className="text-sm font-semibold">Mode</p>
        <select
          className="text-right font-bold w-[30%]  px-2"
          onChange={(e) =>
            setPayingNow({ ...payingNow, paymentMethod: e.target.value })
          }
          value={payingNow.paymentMethod}
        >
          <option>Cash</option>
          <option>Card</option>
          <option>UPI</option>
        </select>
      </div>
      <div className="flex justify-between">
        <p className="text-sm font-semibold">Transaction ID</p>
        <input
          type="text"
          className="text-right font-bold w-[60%] border-2 border-grey-500 px-2"
          value={payingNow.transactionId}
          onChange={(e) =>
            setPayingNow({ ...payingNow, transactionId: e.target.value })
          }
        />
      </div>
    </div>
  );
});

export default PaymentComponent;
