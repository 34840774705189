import React, { useCallback, useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import PageLoading from "../../componentsOwner/PageLoading";
import { FaShop } from "react-icons/fa6";
import { FaEye } from "react-icons/fa";

import { MdArrowBack, MdEdit } from "react-icons/md";
import { AiFillHome } from "react-icons/ai";
import { BASE_URL } from "../../utils/services";

import Loading from "../../components/Loading";
import FloatingAddButton from "../../components/FloatingAddButton";
import FoodMenuCrud from "../../componentsOwner/FoodMenuCrud";
import ServerSideTable from "../../components/ServerSideTable ";
import moment from "moment";
import { customerPhone, formatCurrency } from "../../utils/helper";
import OrderDetailModal from "../../componentsOwner/OrderDetailModal";
import { useResturantContext } from "../../context/ResturantContext";

const Orders = () => {
  const { restaurantId } = useParams();
  const { restaurant } = useResturantContext();

  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [crudData, setCrudData] = useState(null);

  const [loading, setLoading] = useState(false);

  const [refresh, setRefresh] = useState(0);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = (orderId) => {
    setSelectedOrderId(orderId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedOrderId(null);
  };

  const renderAction = (item) => {
    return (
      <div className="flex gap-2">
        <MdEdit
          onClick={() => handleCrudModal(item.id, "edit")}
          className="text-xl mr-2 cursor-pointer text-edit hover:text-editHover"
        />
        <FaEye
          onClick={() => openModal(item.id)}
          // onClick={() => handleOrderDetail(item.id)}
          className="text-xl mr-2 cursor-pointer text-gray-600 hover:text-gray-800"
        />
        {/* <MdDelete
            onClick={() => handleCrudModal(item.id, "delete")}
            className="text-xl cursor-pointer text-delete hover:text-deleteHover"
          /> */}
      </div>
    );
  };

  const handleOrderDetail = async (orderId) => {
    try {
      const res = await fetch(
        `${BASE_URL}/owner/orders/${restaurantId}/${orderId}`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      const data = await res.json();
      if (res.status === 200) {
        console.log(data);
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      setError(error.message);
      console.error("Error fetching orders:", error);
    }
  };

  const fetchOrders = useCallback(
    async ({ pageIndex, pageSize, sortBy, filters }) => {
      try {
        setLoading(true);
        const res = await fetch(`${BASE_URL}/owner/orders/${restaurantId}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            pageIndex,
            pageSize,
            sortBy,
            filters,
          }),
        });
        const data = await res.json();

        if (res.status === 200) {
          // setRestaurant(data.restaurant);
          return {
            data: data.orders,
            pageCount: data.pageCount,
          };
        } else if (res.status === 403) {
          window.alert("Unauthorized Access.");
        } else {
          console.error("Failed to fetch orders");
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        setLoading(false);
      }
    },
    [restaurantId]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Order No",
        accessor: "id",
        Cell: ({ value }) => `#${value}`,
        Filter: ({ column }) => (
          <input
            value={column.filterValue || ""}
            onChange={(e) => column.setFilter(e.target.value || undefined)}
            placeholder="without #"
            className="p-2 bg-bg outline-none focus:outline-black w-24"
          />
        ),
      },
      {
        Header: "Date & time",
        accessor: "updatedAt",
        Cell: ({ value, row }) => moment(value).format("DD-MM-YYYY hh:MM A"),
        // Filter example
        // Filter: ({ column }) => (
        //   <input
        //     type="date"
        //     value={column.filterValue || ""}
        //     onChange={(e) => column.setFilter(e.target.value || undefined)}
        //     placeholder="Date"
        //     className="p-2 bg-bg outline-none focus:outline-black"
        //   />
        // ),
      },
      {
        Header: "Customer",
        accessor: "customer.phone",
        Cell: ({ value, row }) => customerPhone(value),
        Filter: ({ column }) => (
          <input
            value={column.filterValue || ""}
            onChange={(e) => column.setFilter(e.target.value || undefined)}
            placeholder="mobile"
            className="p-2 bg-bg outline-none focus:outline-black w-28"
          />
        ),
      },
      {
        Header: "Type",
        accessor: "orderType",

        Filter: ({ column }) => (
          <select
            value={column.filterValue || ""}
            onChange={(e) => column.setFilter(e.target.value || undefined)}
            className="p-2 bg-bg outline-none focus:outline-black"
          >
            <option value="">All</option>
            {restaurant &&
              Object.values(restaurant?.orderTypes).map((type) => (
                <option key={type.value} value={type.value}>
                  {type.value}
                </option>
              ))}
          </select>
        ),
      },
      {
        Header: "Status",
        accessor: "status",

        Filter: ({ column }) => (
          <select
            value={column.filterValue || ""}
            onChange={(e) => column.setFilter(e.target.value || undefined)}
            className="p-2 bg-bg outline-none focus:outline-black"
          >
            <option value="">All</option>
            <option value="BUYER_CANCELLED">BUYER-CANCELLED</option>
            <option value="COMPLETED">COMPLETED</option>
            <option value="CONFIRMED">CONFIRMED</option>
            <option value="DELIVERED">DELIVERED</option>
            <option value="EXCHANGED">EXCHANGED</option>
            <option value="FAILED">FAILED</option>
            <option value="PENDING">PENDING</option>
            <option value="PROCESSING">PROCESSING</option>
            <option value="REFUNDED">REFUNDED</option>
            <option value="RETURNED">RETURNED</option>
            <option value="RETURN_REQUESTED">RETURN REQUESTED</option>
            <option value="SELLER_CANCELLED">SELLER-CANCELLED</option>
            <option value="SHIPPED">SHIPPED</option>
          </select>
        ),
      },
      {
        Header: "Bill Value",
        accessor: "totalAmount",
        Cell: ({ value, row }) => formatCurrency(value),

        Filter: ({ column }) => (
          <input
            value={column.filterValue || ""}
            onChange={(e) => column.setFilter(e.target.value || undefined)}
            placeholder=""
            className="p-2 bg-bg outline-none focus:outline-black w-24"
          />
        ),
      },

      {
        Header: "Actions",
        accessor: (row) => renderAction(row),
        disableSortBy: true,
        //   Cell: ({ row }) => renderAction(row.original),
      },
    ],
    [restaurant]
  );

  const triggerRefresh = () => {
    setRefresh((prev) => prev + 1);
  };

  // useEffect(() => {
  //   const fetchRestaurant = async () => {
  //     const res = await fetch(`${BASE_URL}/owner/restaurant/${restaurantId}`, {
  //       method: "GET",
  //       credentials: "include",
  //     });
  //     const data = await res.json();
  //     if (res.status === 200) {
  //       setRestaurant({ ...data.restaurant, orderTypes: data.ORDER_TYPES });
  //     } else if (res.status === 403) {
  //       window.alert("Unauthorized Access.");
  //     } else {
  //       setError("Failed to fetch restaurant details");
  //     }
  //   };
  //   fetchRestaurant();
  // }, [restaurantId]);

  const handleCrudModal = (id, action = "create") => {
    // e.preventDefault();

    setCrudData({ action, data: action === "create" ? null : id });
    setShowModal(true);
  };

  if (!restaurant) {
    return (
      <>
        <PageLoading link={"/owner/owner-home"} />
      </>
    );
  }

  return (
    <>
      <div className="flex flex-col justify-center items-center">
        <div>
          {error && (
            <p className="text-red-500">
              {error}
              <br />
              <Link to="/owner/owner-home" className="icon" title="Back Home">
                <AiFillHome size={25} />
              </Link>
            </p>
          )}
        </div>
        <div className="flex justify-start items-center bg-white  w-full p-4 mb-2 rounded-lg max-w-6xl">
          <Link
            to={`/owner/restaurant/${restaurantId}`}
            className=" text-xs inline-flex items-center"
          >
            <MdArrowBack size={20} className="mr-2" />{" "}
            <span className="hidden sm:block">Back</span>
          </Link>
          <p className="text-xs sm:text-sm font-bold sm:ml-2">Orders</p>
        </div>

        {loading && <Loading />}
        <div className="w-full bg-white max-w-6xl rounded-lg shadow-md">
          <ServerSideTable
            columns={columns}
            fetchData={fetchOrders}
            refresh={refresh} // Pass the refresh state as a prop
          />
          {/* <MenuItemsTable menuItems={restaurant?.menuItems} showAction={true}showCategory={true} renderAction={renderAction}/> */}
        </div>
      </div>
      {showModal && (
        <FoodMenuCrud
          restaurantId={restaurantId}
          action={crudData?.action}
          data={crudData?.data}
          onClose={() => {
            setShowModal(false);
            setCrudData(null);
            triggerRefresh();
            // fetchMenuItems();
          }}
        />
      )}
      <FloatingAddButton title={"New Food menu item"}>
        <Link to={`/owner/restaurant/${restaurantId}/pos`}>
          <FaShop size={20} />
        </Link>
      </FloatingAddButton>
      <OrderDetailModal
        restaurantId={restaurantId}
        orderId={selectedOrderId}
        isOpen={isModalOpen}
        closeModal={closeModal}
      />
    </>
  );
};

export default Orders;
