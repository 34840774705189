import React, { useEffect, useMemo, useState } from "react";
import { useTable, useSortBy, usePagination, useFilters } from "react-table";

const ServerSideTable = ({ columns, fetchData, refresh }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  // const [totalRows, setTotalRows] = useState(10);

  const fetchTableData = async ({ pageIndex, pageSize, sortBy, filters }) => {
    setLoading(true);

    const result = await fetchData({ pageIndex, pageSize, sortBy, filters });

    setData(result.data);
    setPageCount(result.pageCount);
    // setTotalRows(result.total);
    setLoading(false);
  };
  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: () => null,
    }),
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount: controlledPageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy, filters },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      manualPagination: true,
      pageCount,
      manualSortBy: true,
      manualFilters: true,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    fetchTableData({ pageIndex, pageSize, sortBy, filters });
  }, [pageIndex, pageSize, sortBy, filters, refresh]);

  return (
    <div className="p-4">
      {/* Table */}
      <div className="overflow-x-auto">
        <table
          {...getTableProps()}
          className="min-w-full divide-y divide-gray-200 bg-white"
        >
          <thead className="bg-gray-50">
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="px-3 py-3"
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                    {/* <div>{column.canFilter ? column.render('Filter') : null}</div> */}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="divide-y divide-gray-200">
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className="bg-white hover:bg-gray-100"
                >
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      className="px-3 py-3 whitespace-nowrap text-sm text-gray-500"
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr className="bg-gray-50 text-xs font-medium text-gray-500 uppercase tracking-wider">
              {headerGroups[0].headers.map((column) => (
                <td key={column.id} className="px-3 py-3">
                  {column.canFilter ? column.render("Filter") : null}
                </td>
              ))}
            </tr>
          </tfoot>
        </table>
      </div>

      <div className="flex flex-col items-center md:flex-row md:justify-between py-3 mt-4">
        {/* Pagination Buttons */}
        <div className="flex space-x-2 mb-2 md:mb-0">
          <button
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            className="px-4 py-2 text-sm font-medium text-blue-600 bg-blue-100 border border-blue-300 rounded hover:bg-blue-200 disabled:opacity-50"
          >
            {"<<"}
          </button>
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="px-4 py-2 text-sm font-medium text-blue-600 bg-blue-100 border border-blue-300 rounded hover:bg-blue-200 disabled:opacity-50"
          >
            {"<"}
          </button>
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="px-4 py-2 text-sm font-medium text-blue-600 bg-blue-100 border border-blue-300 rounded hover:bg-blue-200 disabled:opacity-50"
          >
            {">"}
          </button>
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            className="px-4 py-2 text-sm font-medium text-blue-600 bg-blue-100 border border-blue-300 rounded hover:bg-blue-200 disabled:opacity-50"
          >
            {">>"}
          </button>
        </div>

        {/* Pagination Info */}
        <div className="text-sm text-gray-500 mb-2 md:mb-0">
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </div>

        {/* <div className="text-sm text-gray-500 mb-2 md:mb-0">
          Total Rows: <strong>{totalRows}</strong>
        </div> */}

        {/* Go to Page Input */}
        <div className="flex items-center space-x-2 mb-2 md:mb-0">
          <span className="text-sm text-gray-500">Go to page:</span>
          <input
            type="number"
            defaultValue={pageIndex + 1}
            max={pageCount}
            min={1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            className="border border-gray-300 rounded-md px-2 py-1 text-sm"
            style={{ width: "100px" }}
          />
        </div>

        {/* Page Size Selector */}
        <div className="flex items-center space-x-2">
          <span className="text-sm text-gray-500">Show:</span>
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="border border-gray-300 rounded-md px-2 py-1 text-sm"
          >
            {[10, 20, 30, 40, 50].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
      </div>

      {loading && <div className="text-center text-gray-500">Loading...</div>}
    </div>
  );
};

export default ServerSideTable;
