import React, { useCallback, useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import PageLoading from "../../componentsOwner/PageLoading";
import {
  MdArrowBack,
  MdDelete,
  MdEdit,
  MdToggleOn,
  MdToggleOff,
} from "react-icons/md";
import { BASE_URL } from "../../utils/services";

import Loading from "../../components/Loading";
import FloatingAddButton from "../../components/FloatingAddButton";
import ServerSideTable from "../../components/ServerSideTable ";
import TableCrud from "../../componentsOwner/TableCrud";
import { useResturantContext } from "../../context/ResturantContext";

const TableMaster = () => {
  const { restaurantId } = useParams();
  const { restaurant } = useResturantContext();

  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [crudData, setCrudData] = useState(null);

  const [loading, setLoading] = useState(false);

  const [refresh, setRefresh] = useState(0);
  const renderAction = (item) => {
    return (
      <div className="flex gap-2">
        {item.status === "AVAILABLE" && (
          <MdToggleOn
            onClick={() => toggleAvailability(item.id)}
            className="text-xl cursor-pointer text-available hover:text-availableHover"
          />
        )}
        {item.status === "UNAVAILABLE" && (
          <MdToggleOff
            onClick={() => toggleAvailability(item.id)}
            className="text-xl cursor-pointer text-unavailable hover:text-unavailableHover"
          />
        )}
        {item.status !== "AVAILABLE" && item.status !== "UNAVAILABLE" && (
          <MdToggleOn className="text-xl text-disabled " />
        )}
        <MdEdit
          onClick={() => handleCrudModal(item.id, "edit")}
          className="text-xl mr-2 cursor-pointer text-edit hover:text-editHover"
        />
        <MdDelete
          onClick={() => handleCrudModal(item.id, "delete")}
          className="text-xl cursor-pointer text-delete hover:text-deleteHover"
        />
      </div>
    );
  };

  const toggleAvailability = async (id) => {
    try {
      const res = await fetch(
        `${BASE_URL}/owner/restaurant/${restaurantId}/tables/${id}/toggle-status`,
        {
          method: "PATCH",
          credentials: "include",
        }
      );

      if (res.status === 200) {
        setError(null);
        triggerRefresh();
      } else {
        throw new Error(res.json);
        // console.error('Failed to toggle availability');
      }
    } catch (error) {
      setError("Failed to toggle availability");
      console.error("Error toggling availability:", error);
    }
  };

  const fetchTables = useCallback(
    async ({ pageIndex, pageSize, sortBy, filters }) => {
      try {
        setLoading(true);
        const res = await fetch(
          `${BASE_URL}/owner/restaurant/${restaurantId}/tables`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
              pageIndex,
              pageSize,
              sortBy,
              filters,
            }),
          }
        );
        const data = await res.json();

        if (res.status === 200) {
          // setRestaurant(data.restaurant);
          return {
            data: data.tables,
            pageCount: data.pageCount,
          };
        } else if (res.status === 403) {
          window.alert("Unauthorized Access.");
        } else {
          console.error("Failed to fetch restaurant details");
        }
      } catch (error) {
        console.error("Error fetching restaurant details:", error);
      } finally {
        setLoading(false);
      }
    },
    [restaurantId]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Table#",
        accessor: "tableNumber",
        // Filter example
        Filter: ({ column }) => (
          <input
            value={column.filterValue || ""}
            onChange={(e) => column.setFilter(e.target.value || undefined)}
            placeholder="Search table no"
            className="p-2 bg-bg outline-none focus:outline-black"
          />
        ),
      },
      {
        Header: "Capacity",
        accessor: "capacity",
        Filter: ({ column }) => (
          <input
            value={column.filterValue || ""}
            onChange={(e) => column.setFilter(e.target.value || undefined)}
            placeholder="Search capacity"
            className="p-2 bg-bg outline-none focus:outline-black"
          />
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => value.toUpperCase(),
        Filter: ({ column }) => (
          <select
            value={column.filterValue || ""}
            onChange={(e) => column.setFilter(e.target.value || undefined)}
            className="p-2 bg-bg outline-none focus:outline-black"
          >
            <option value="">All</option>
            <option value="AVAILABLE">AVAILABLE</option>
            <option value="OCCUPIED">OCCUPIED</option>
            <option value="RESERVED">RESERVED</option>
            <option value="UNAVAILABLE">UNAVAILABLE</option>
          </select>
        ),
      },

      {
        Header: "Actions",
        accessor: (row) => renderAction(row),
        disableSortBy: true,
        //   Cell: ({ row }) => renderAction(row.original),
      },
    ],
    []
  );

  const triggerRefresh = () => {
    setRefresh((prev) => prev + 1);
  };

  const handleCrudModal = (id, action = "create") => {
    // e.preventDefault();

    setCrudData({ action, data: action === "create" ? null : id });
    setShowModal(true);
  };

  if (!restaurant) {
    return (
      <>
        <PageLoading link={"/owner/owner-home"} />
      </>
    );
  }

  return (
    <>
      <div className="flex flex-col justify-center items-center ">
        <div className="flex justify-start items-center bg-white  w-full p-4 mb-2 rounded-lg max-w-6xl">
          <Link
            to={`/owner/restaurant/${restaurantId}`}
            className=" text-xs inline-flex items-center"
          >
            <MdArrowBack size={20} className="mr-2" />{" "}
            <span className="hidden sm:block">Back</span>
          </Link>
          <p className="text-xs sm:text-sm font-bold sm:ml-2">Tables</p>
        </div>

        {loading && <Loading />}
        <div className="w-full bg-white max-w-6xl  rounded-lg shadow-md">
          <div className="text-center">
            {error && <p className="text-red-500">{error}</p>}
          </div>
          <ServerSideTable
            columns={columns}
            fetchData={fetchTables}
            refresh={refresh} // Pass the refresh state as a prop
          />
          {/* <MenuItemsTable menuItems={restaurant?.menuItems} showAction={true}showCategory={true} renderAction={renderAction}/> */}
        </div>
      </div>
      {showModal && (
        <TableCrud
          restaurantId={restaurantId}
          action={crudData?.action}
          data={crudData?.data}
          onClose={() => {
            setShowModal(false);
            setCrudData(null);
            triggerRefresh();
            // fetchMenuItems();
          }}
        />
      )}
      <FloatingAddButton title={"New Table"} onClick={handleCrudModal} />
    </>
  );
};

export default TableMaster;
