import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import PageLoading from "./PageLoading";
import { MdDelete } from "react-icons/md";
import { AiFillHome } from "react-icons/ai";
import { Buffer } from "buffer";
import { BASE_URL } from "../utils/services";
import Loading from "../components/Loading";
import { formatCurrency } from "../utils/helper";
import { BiSolidFoodMenu } from "react-icons/bi";
import { MdDining } from "react-icons/md";
import { FaKitchenSet, FaShop } from "react-icons/fa6";
import { IoIosListBox } from "react-icons/io";
import { useResturantContext } from "../context/ResturantContext";

const RestaurentDetails = () => {
  const navigate = useNavigate();
  const { restaurant } = useResturantContext();
  const { restaurantId } = useParams();
  // const [restaurant, setRestaurant] = useState(null);
  const [error, setError] = useState(null);

  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   const fetchRestaurantDetails = async () => {
  //     try {
  //       const res = await fetch(
  //         `${BASE_URL}/owner/restaurant/${restaurantId}`,
  //         {
  //           method: "GET",
  //           credentials: "include",
  //         }
  //       );
  //       const data = await res.json();
  //       console.log(data);
  //       if (res.status === 200) {
  //         setRestaurant(data.restaurant);
  //         setRestaurantData(data.restaurant);
  //       } else if (res.status === 403) {
  //         window.alert("Unauthorized Access.");
  //       } else {
  //         console.error("Failed to fetch restaurant details");
  //       }
  //     } catch (error) {
  //       console.error("Error fetching restaurant details:", error);
  //     }
  //   };

  //   fetchRestaurantDetails();
  // }, [restaurantId]);

  const handleDelete = async () => {
    const userConfirmed = window.confirm(
      `Are you sure you want to Delete this restaurant?`
    );

    if (!userConfirmed) {
      return; // Exit the function if the user does not confirm
    }
    setLoading(true);
    try {
      const res = await fetch(
        `${BASE_URL}/owner/delete-restaurant/${restaurantId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );

      if (res.status === 200) {
        window.alert("Restaurant Deleted Successfully.");
        navigate("/owner/owner-home");
      } else if (res.status === 403) {
        setError("Unauthorized Access.");
      } else if (res.status === 404) {
        setError("Restaurant not found.");
      } else {
        setError("Failed to delete restaurant. Please try again.");
      }
    } catch (error) {
      console.error(error);
      setError("Failed to delete restaurant.");
    } finally {
      setLoading(false);
    }
  };

  if (!restaurant) {
    return (
      <>
        <PageLoading link={"/owner/owner-home"} />
      </>
    );
  }

  return (
    <div className="flex flex-col justify-center items-center">
      {loading && <Loading />}
      <div>
        {error && (
          <p className="text-red-500">
            {error}
            <br />
            <Link to="/owner/owner-home" className="icon" title="Back Home">
              <AiFillHome size={25} />
            </Link>
          </p>
        )}
      </div>

      <div className="flex justify-center flex-wrap gap-4 w-full mb-4">
        <div className="w-full  space-y-2 p-4 bg-white h-max shadow-md rounded-lg">
          <div className="flex flex-row gap-2 sm:gap-4 flex-wrap justify-between sm:justify-start">
            <Link
              title="Food Menu"
              to={`/owner/restaurant/${restaurantId}/food-menu`}
              className="button bg-green-600 w-24 h-24 sm:w-auto sm:h-auto text-white py-2 px-4 rounded hover:bg-green-800 flex flex-col sm:flex-row justify-center items-center gap-2"
            >
              <BiSolidFoodMenu size={30} />
              <span className="block text-sm">Menu</span>
            </Link>
            <Link
              title="Tables"
              to={`/owner/restaurant/${restaurantId}/tables`}
              className="button bg-teal-600 w-24 h-24 sm:w-auto sm:h-auto text-white py-2 px-4 rounded hover:bg-teal-800 flex flex-col sm:flex-row justify-center items-center gap-2"
            >
              <MdDining size={30} />
              <span className=" block text-sm">Tables</span>
            </Link>
            <Link
              title="POS"
              to={`/owner/restaurant/${restaurantId}/pos`}
              className="button bg-orange-600 w-24 h-24 sm:w-auto sm:h-auto text-white py-2 px-4 rounded hover:bg-orange-800 flex flex-col sm:flex-row justify-center items-center gap-2"
            >
              <FaShop size={30} />
              <span className=" block text-sm">POS</span>
            </Link>
            <Link
              title="Orders"
              to={`/owner/restaurant/${restaurantId}/orders`}
              className="button bg-purple-600 w-24 h-24 sm:w-auto sm:h-auto text-white py-2 px-4 rounded hover:bg-purple-800 flex flex-col sm:flex-row justify-center items-center gap-2"
            >
              <IoIosListBox size={30} />

              <span className=" block text-sm">Orders</span>
            </Link>
            <Link
              title="Kitchen"
              to={`/owner/restaurant/${restaurantId}/kitchen`}
              className="button bg-cyan-600 w-24 h-24 sm:w-auto sm:h-auto text-white py-2 px-4 rounded hover:bg-cyan-800 flex flex-col sm:flex-row justify-center items-center gap-2"
            >
              <FaKitchenSet size={30} />

              <span className=" block text-sm">Kitchen</span>
            </Link>
            <button
              type="button"
              title="Delete"
              onClick={handleDelete}
              className="button bg-red-700 w-24 h-24 sm:w-auto sm:h-auto text-white py-2 px-4 rounded hover:bg-red-900 flex flex-col sm:flex-row justify-center items-center gap-2"
            >
              <MdDelete size={30} />
              <span className=" block text-sm">Delete</span>
            </button>
          </div>
        </div>
      </div>
      <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4 p-4 bg-white shadow-md rounded-lg h-max">
        <div className="border p-2">
          <div className="text-xl font-semibold mb-2">Basic Information</div>
          <p>Name: {restaurant.name}</p>
          <p>City: {restaurant.city}</p>
          <p>Area: {restaurant.area}</p>
          <p>Location: {restaurant.location}</p>
          <p>Contact: {restaurant.contactNumber}</p>
        </div>
        <div className="border p-2">
          <div className="text-xl font-semibold mb-2">
            Restaurant Informations
          </div>
          <p>
            Open:{" "}
            {restaurant.startTime && restaurant.endTime
              ? `${restaurant.startTime} to ${restaurant.endTime}`
              : restaurant.startTime
              ? restaurant.startTime
              : restaurant.endTime
              ? restaurant.endTime
              : "Not Specified"}
          </p>
          <p>
            Cuisine:{" "}
            {!restaurant.cuisine || restaurant.cuisine === ""
              ? "Not Specified"
              : restaurant.cuisine.join(",")}
          </p>
          <p>
            Types:{" "}
            {!restaurant.types || restaurant.types === ""
              ? "Not Specified"
              : restaurant.types.join(",")}
          </p>
        </div>
        <div className="border p-2">
          <div className="text-xl font-semibold mb-2">Tax & other charges</div>
          <p>
            GST Number:{" "}
            {restaurant.gstNumber ? restaurant.gstNumber : "Not Specified"}
          </p>

          <p>
            GST Slab:{" "}
            {restaurant.taxRate ? `${restaurant.taxRate}%` : "Not Specified"}
          </p>
          <p>
            Service Charge:{" "}
            {restaurant.serviceChargeValue
              ? restaurant.serviceChargeType === "FIXED"
                ? formatCurrency(
                    restaurant.serviceChargeValue,
                    restaurant.currency
                  )
                : `${restaurant.serviceChargeValue}%`
              : "Not Specified"}
          </p>
        </div>
        <div className="border p-2">
          <div className="text-xl font-semibold mb-2">
            Additional Informations
          </div>

          <p>
            Average For 2:{" "}
            {restaurant.averageCostForTwo
              ? formatCurrency(
                  restaurant.averageCostForTwo,
                  restaurant.currency
                )
              : "Not Specified"}
          </p>

          <p className="break-words">
            Amenities:{" "}
            {restaurant.amenities && restaurant.amenities.length > 0
              ? restaurant.amenities.join(",")
              : "Not Specified"}
          </p>
        </div>
        <div className="grid-rows-subgrid sm:col-span-2 border p-2">
          <div className="text-xl font-semibold mb-2">Images</div>

          <div className="flex flex-wrap gap-2 mb-2">
            {restaurant.images
              ? restaurant.images.map((image, index) => (
                  <img
                    key={index}
                    src={`data:${image.contentType};base64,${Buffer.from(
                      image.data
                    ).toString("base64")}`}
                    alt={`Restaurant ${index + 1}`}
                    className="w-24 h-24 object-cover"
                  />
                ))
              : "Not Specified"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestaurentDetails;
