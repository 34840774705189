import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../utils/services";
import Invoice from "../../componentsOwner/Invoice";
export default function InvoicePage() {
  const [isloading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [order, setOrder] = useState(null);
  const { restaurantId, orderId } = useParams();
  useEffect(() => {
    const fetchOrder = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${BASE_URL}/owner/orders/${restaurantId}/${orderId}`,
          {
            method: "GET",
            credentials: "include",
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch order");
        }
        const data = await response.json();
        setOrder(data);
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrder();
  }, [orderId, restaurantId]);
  if (isloading) return <div>Loading...</div>;
  if (isError) return <div>Error fetching order</div>;
  return <Invoice order={order} size={"58mm"} />;
}
