import React, { useCallback, useEffect, useState } from "react";
import { BASE_URL } from "../utils/services";
import Loading from "../components/Loading";
import RestaurantTable from "./RestaurantTable";
const initialFormData = {
  tableNumber: "",
  capacity: "",
  status: "AVAILABLE",
  id: "",
};
const statuses = [
  { label: "Available", value: "AVAILABLE" },
  { label: "Occupied", value: "OCCUPIED" },
  { label: "Reserved", value: "RESERVED" },
  { label: "UnAvailable", value: "UNAVAILABLE" },
];
export default function TableCrud({
  onClose,
  data,
  action = "detail",
  restaurantId,
}) {
  const [error, setError] = useState(null);
  const [table, setTable] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tables, setTables] = useState([]);
  const [formData, setFormData] = useState(initialFormData);

  let name, value;
  const handleChange = (e) => {
    name = e.target.name;
    value = e.target.value;
    if (name === "isAvailable") {
      value = e.target.checked;
    }
    setFormData({ ...formData, [name]: value });
  };
  const fetchTable = useCallback(async () => {
    const url = `${BASE_URL}/owner/restaurant/${restaurantId}/tables/${data}`;
    const res = await fetch(url, {
      method: "GET",

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    const table = await res.json();
    setTable(table);
    if (action === "edit") {
      setFormData({
        tableNumber: table.tableNumber,
        capacity: table.capacity,
        id: table.id,
        status: table.status,
      });
    }
  }, [data, action]);
  const addTable = async () => {
    // const { name, description } = formData;
    const res = await fetch(
      `${BASE_URL}/owner/restaurant/${restaurantId}/add-table`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(formData),
      }
    );
    return res;
  };
  const editTable = async () => {
    const res = await fetch(
      `${BASE_URL}/owner/restaurant/${restaurantId}/tables/${data}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(formData),
      }
    );
    return res;
  };
  const deleteTable = async () => {
    try {
      setLoading(true);
      const res = await fetch(
        `${BASE_URL}/owner/restaurant/${restaurantId}/tables/${data}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      // const data = await res.json();
      // console.log(data);

      if (res.status === 404) {
        window.alert("User not found.");
      } else if (res.status === 500) {
        window.alert("Internal server error.");
      } else if (res.status === 204) {
        setFormData(initialFormData);

        onClose();
      } else {
        window.alert(res.json);
      }
    } catch (error) {
      window.alert("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async (e, keepModal = true) => {
    e.preventDefault();
    setLoading(true);

    try {
      let res;
      if (action === "create") {
        res = await addTable();
      } else if (action === "edit") {
        res = await editTable();
      }
      const data = await res.json();
      // console.log(data);

      if (res.status === 422 || !data) {
        window.alert("All Fields are Mandatory.");
      } else if (res.status === 404) {
        window.alert("User not found.");
      } else if (res.status === 500) {
        window.alert("Internal server error.");
      } else if (res.status === 200 || res.status === 201) {
        setFormData(initialFormData);
        keepModal &&
          action === "create" &&
          setTables([...tables, { ...formData }]);

        window.alert(
          action === "create"
            ? "New Table created successfully."
            : `Table Number - ${table.tableNumber} updated successfully.`
        );
        (keepModal && action === "create") || onClose();
      } else {
        window.alert(res.json);
      }
    } catch (error) {
      window.alert("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (action !== "create" && data) {
      fetchTable();
    }
  }, [action, data, fetchTable]);

  return (
    <div className="fixed flex flex-col justify-center items-center top-0 left-0 w-full h-full bg-filterFloat z-20">
      <div className="flex flex-col justify-center items-center gap-6 bg-white p-12 shadow-review">
        {error && <p className="text-red-500">{error}</p>}
        <div className="flex flex-col justify-center items-center">
          <p className="text-md font-bold">
            {action === "create"
              ? "New Table"
              : !table
              ? "Loading..."
              : `Table Number : ${table?.tableNumber}`}
          </p>

          {action !== "create" && (
            <>
              <p className="text-sm">Capacity : {table?.capacity}</p>
              <p className="text-sm">Status : {table?.capacity}</p>
            </>
          )}
        </div>
        {action !== "delete" && (
          <form
            className="flex flex-col items-center gap-4"
            onSubmit={(e) => handleEdit(e)}
          >
            <input
              className="w-[280px] p-4 bg-bg outline-none"
              placeholder="Table Number"
              type="text"
              name="tableNumber"
              value={formData.tableNumber}
              onChange={handleChange}
              required
            />
            <input
              className="w-[280px] p-4 bg-bg outline-none"
              name="capacity"
              placeholder="Capacity"
              value={formData.capacity}
              onChange={handleChange}
              required
            />
            <select
              className="w-[280px] p-4 bg-bg outline-none"
              name="status"
              value={formData.status}
              onChange={handleChange}
              required
            >
              <option value="">Select Status</option>
              {statuses.map((status) => (
                <option key={status.value} value={status.value}>
                  {status.label}
                </option>
              ))}
            </select>

            <div className="w-full flex flex-row gap-2">
              {action === "create" && (
                <>
                  <button
                    className=" p-3 bg-theme text-white font-bold text-xl hover:opacity-80"
                    type="submit"
                  >
                    {loading ? "Saving..." : "Save & Add New"}
                  </button>
                  <button
                    className=" p-3 bg-theme text-white font-bold text-xl hover:opacity-80"
                    type="button"
                    onClick={(e) => handleEdit(e, false)}
                  >
                    {loading ? "Saving..." : "Save & Close"}
                  </button>
                </>
              )}
              {action === "edit" && (
                <button
                  className="w-[280px] p-3 bg-theme text-white font-bold text-xl hover:opacity-80"
                  type="submit"
                >
                  {loading ? "Saving..." : "Save"}
                </button>
              )}
            </div>
          </form>
        )}
        {action === "delete" && (
          <div className="flex flex-row gap-2">
            <button
              className=" p-3 bg-theme text-white font-bold text-xl hover:opacity-80"
              type="button"
              onClick={deleteTable}
            >
              {loading ? "Deleting..." : "Delete"}
            </button>
            <button
              className=" p-3 bg-theme text-white font-bold text-xl hover:opacity-80"
              type="button"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        )}
        {tables.length > 0 && <RestaurantTable tables={tables} />}
      </div>
      <div
        className="flex justify-center items-center mt-3 bg-border h-10 w-10 rounded-full text-white text-3xl cursor-pointer"
        onClick={onClose}
      >
        ×
      </div>
      {loading && <Loading />}
    </div>
  );
}
