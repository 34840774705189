// OwnerContext.js
import React, { createContext, useState, useContext } from "react";

// Create the context
const OwnerContext = createContext();

// Provider component
export const OwnerProvider = ({ children }) => {
  const [owner, setOwner] = useState(null); // Initially null, set to owner data after login

  const loginOwner = (ownerData) => {
    setOwner(ownerData);
  };

  const logoutOwner = () => {
    setOwner(null);
  };

  return (
    <OwnerContext.Provider value={{ owner, loginOwner, logoutOwner }}>
      {children}
    </OwnerContext.Provider>
  );
};

// Custom hook to use the OwnerContext
export const useOwner = () => {
  return useContext(OwnerContext);
};
