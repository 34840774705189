import React, { useCallback, useEffect, useState } from "react";
import { BASE_URL } from "../utils/services";
import Loading from "../components/Loading";
import MenuItemsTable from "./MenuItemsTable";
import AsyncSelect from "react-select/async";
import { GrSquare } from "react-icons/gr";
import { FaEgg } from "react-icons/fa";
const initialFormData = {
  name: "",
  description: "",
  id: "",
  price: "",
  category: null,
  isAvailable: false,
  itemType: "NON-VEG",
};
export default function FoodMenuCrud({
  onClose,
  data,
  action = "detail",
  restaurantId,
}) {
  const [error, setError] = useState(null);
  const [menuItem, setMenuItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [formData, setFormData] = useState(initialFormData);

  let name, value;
  const handleChange = (e) => {
    name = e.target.name;
    value = e.target.value;
    if (name === "isAvailable") {
      value = e.target.checked;
    }

    setFormData({ ...formData, [name]: value });
  };
  const fetchMenuItem = useCallback(async () => {
    // const params = new URLSearchParams({
    //   includeMenuItems: action === "detail",
    //   includeRestaurants: action === "detail",
    // });

    const url = `${BASE_URL}/owner/restaurant/${restaurantId}/menu-item/${data}`;
    const res = await fetch(url, {
      method: "GET",

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    const menu = await res.json();
    setMenuItem(menu.menuItem);
    if (action === "edit") {
      setFormData({
        name: menu.menuItem.name,
        description: menu.menuItem.description,
        id: menu.menuItem.id,
        price: menu.menuItem.pricings[0]?.price,
        category: {
          label: menu.menuItem.category.name,
          value: menu.menuItem.category.id,
        },
        isAvailable: menu.menuItem.isAvailable,
        itemType: menu.menuItem.itemType,
      });
    }
  }, [data, action]);
  const addMenuItem = async () => {
    // const { name, description } = formData;
    const res = await fetch(
      `${BASE_URL}/owner/restaurant/${restaurantId}/add-menu-item`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(formData),
      }
    );
    return res;
  };
  const editMenuItem = async () => {
    const res = await fetch(
      `${BASE_URL}/owner/restaurant/${restaurantId}/menu-item/${data}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(formData),
      }
    );
    return res;
  };
  const deleteMenuItem = async () => {
    try {
      setLoading(true);
      const res = await fetch(
        `${BASE_URL}/owner/restaurant/${restaurantId}/menu-item/${data}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      // const data = await res.json();
      // console.log(data);

      if (res.status === 404) {
        window.alert("User not found.");
      } else if (res.status === 500) {
        window.alert("Internal server error.");
      } else if (res.status === 200) {
        setFormData(initialFormData);

        onClose();
      } else {
        window.alert(res.json);
      }
    } catch (error) {
      window.alert("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async (e, keepModal = true) => {
    e.preventDefault();
    setLoading(true);

    try {
      let res;
      if (action === "create") {
        res = await addMenuItem();
      } else if (action === "edit") {
        res = await editMenuItem();
      }
      const data = await res.json();
      // console.log(data);

      if (res.status === 422 || !data) {
        window.alert("All Fields are Mandatory.");
      } else if (res.status === 404) {
        window.alert("User not found.");
      } else if (res.status === 500) {
        window.alert("Internal server error.");
      } else if (res.status === 200) {
        setFormData(initialFormData);
        keepModal &&
          action === "create" &&
          setMenuItems([
            ...menuItems,
            { ...formData, category: { name: formData.category.label } },
          ]);

        window.alert(
          action === "create"
            ? "New Menu item created successfully."
            : `Menu Item - ${menuItem.name} updated successfully.`
        );
        (keepModal && action === "create") || onClose();
      } else {
        window.alert(res.json);
      }
    } catch (error) {
      window.alert("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCategories = async (inputValue) => {
    return await fetch(
      `${BASE_URL}/owner/search-menu-categories?query=${inputValue}`,
      {
        method: "GET",

        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        return data.categories.map((category) => ({
          value: category.id,
          label: category.name,
        }));
      });
  };

  useEffect(() => {
    if (action !== "create" && data) {
      fetchMenuItem();
    }
  }, [action, data, fetchMenuItem]);

  return (
    <div className="fixed flex flex-col overflow-y-auto justify-center items-center top-0 left-0 w-full h-full bg-filterFloat z-20">
      <div className="flex flex-col justify-center items-center gap-6 bg-white p-12 shadow-review">
        {error && <p className="text-red-500">{error}</p>}
        <div className="flex flex-col justify-center items-center">
          <p className="text-md font-bold">
            {action === "create"
              ? "New Menu Item"
              : !menuItem
              ? "Loading..."
              : `Menu Item : ${menuItem?.name} (${menuItem.category.name})`}
          </p>

          {action !== "create" && (
            <>
              <p className="text-sm">{menuItem?.description}</p>
              <p className="text-sm">
                Price : {menuItem?.pricings[0].price} | Available :{" "}
                {menuItem?.isAvailable ? "Yes" : "No"}
              </p>
              <p>
                {menuItem?.itemType === "PURE-VEG" ? (
                  <GrSquare color="green" />
                ) : menuItem?.itemType === "EGG-ONLY" ? (
                  <FaEgg color="yellow" />
                ) : (
                  <GrSquare color="red" />
                )}
              </p>
            </>
          )}
        </div>
        {action !== "delete" && (
          <form
            className="flex flex-col items-center gap-4"
            onSubmit={(e) => handleEdit(e)}
          >
            <input
              className="w-[280px] p-4 bg-bg outline-none"
              placeholder="Name"
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <textarea
              className="w-[280px] p-4 bg-bg outline-none"
              name="description"
              placeholder="Description"
              value={formData.description}
              onChange={handleChange}
              required
            />
            <AsyncSelect
              className="w-[280px] p-4 bg-bg outline-none"
              placeholder="Select Parent Category"
              cacheOptions
              defaultOptions
              loadOptions={fetchCategories}
              isClearable
              // hideSelectedOptions
              name="category"
              value={formData.category}
              onChange={(newValue) => {
                setFormData({ ...formData, category: newValue });
              }}
            />
            <label className="text-sm">
              {" "}
              <input
                type="checkbox"
                className="rounded text-pink-500"
                name="isAvailable"
                checked={formData.isAvailable}
                onChange={handleChange}
              />{" "}
              Available
            </label>
            <select
              className="w-[280px] p-4 bg-bg outline-none"
              placeholder="Select type"
              name="itemType"
              value={formData.itemType}
              onChange={handleChange}
              required
            >
              <option value={""}>Select Type</option>
              <option value={"EGG-ONLY"}>Egg-Only</option>
              <option value={"NON-VEG"}>Non-Veg</option>
              <option value={"PURE-VEG"}>Pure-Veg</option>
            </select>

            <input
              className="w-[280px] p-4 bg-bg outline-none"
              placeholder="Price"
              type="text"
              name="price"
              value={formData.price}
              onChange={handleChange}
              required
            />
            <div className="w-full flex flex-row gap-2">
              {action === "create" && (
                <>
                  <button
                    className=" p-3 bg-theme text-white font-bold text-xl hover:opacity-80"
                    type="submit"
                  >
                    {loading ? "Saving..." : "Save & Add New"}
                  </button>
                  <button
                    className=" p-3 bg-theme text-white font-bold text-xl hover:opacity-80"
                    type="button"
                    onClick={(e) => handleEdit(e, false)}
                  >
                    {loading ? "Saving..." : "Save & Close"}
                  </button>
                </>
              )}
              {action === "edit" && (
                <button
                  className="w-[280px] p-3 bg-theme text-white font-bold text-xl hover:opacity-80"
                  type="submit"
                >
                  {loading ? "Saving..." : "Save"}
                </button>
              )}
            </div>
          </form>
        )}
        {action === "delete" && (
          <div className="flex flex-row gap-2">
            <button
              className=" p-3 bg-theme text-white font-bold text-xl hover:opacity-80"
              type="button"
              onClick={deleteMenuItem}
            >
              {loading ? "Deleting..." : "Delete"}
            </button>
            <button
              className=" p-3 bg-theme text-white font-bold text-xl hover:opacity-80"
              type="button"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        )}
        {menuItems.length > 0 && (
          <MenuItemsTable
            menuItems={menuItems}
            setMenuItems={setMenuItems}
            showCategory={true}
          />
        )}
      </div>
      <div
        className="flex justify-center items-center mt-3 bg-border h-10 w-10 rounded-full text-white text-3xl cursor-pointer"
        onClick={onClose}
      >
        ×
      </div>
      {loading && <Loading />}
    </div>
  );
}
