import React from "react";
import { Link, useLocation } from "react-router-dom";

const NotFoundPage = () => {
  const location = useLocation();
  console.log(location);

  return (
    <>
      <div className="flex justify-center items-center h-dvh">
        <div className="text-center">
          <p className="text-9xl font-bold">202</p>
          <p className="text-3xl">
            {" "}
            <span className="text-theme">Ahm,</span> Work in progress.
          </p>
          <p className="text-2xl mb-6">We are working on it. Sachhi!</p>
          <Link
            to="/"
            className="bg-theme text-white font-bold p-3 rounded hover:opacity-80"
          >
            Go Home
          </Link>
        </div>
      </div>
    </>
  );
};

export const Error500 = () => {
  return (
    <>
      <div className="flex justify-center items-center h-dvh">
        <div className="text-center">
          <p className="text-9xl font-bold">500</p>
          <p className="text-3xl">
            {" "}
            <span className="text-danger">Opps!</span> Something went wrong.
          </p>
          <p className="text-2xl mb-6">
            Relax, start again from scratch. We will fix it soon.
          </p>

          <Link
            to="/"
            className="bg-theme text-white font-bold p-3 rounded hover:opacity-80"
          >
            Go Home
          </Link>
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;
