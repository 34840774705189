import { BiSolidMessageSquareAdd } from "react-icons/bi";

export default function FloatingAddButton({ onClick, title, children }) {
  return (
    <button
      type="button"
      className="fixed bottom-8 right-8 bg-theme text-white rounded-full p-4 shadow-lg hover:bg-blue-700"
      onClick={onClick}
      title={title}
    >
      {children ? children : <BiSolidMessageSquareAdd size={24} />}
    </button>
  );
}
