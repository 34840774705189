import React, { useEffect, useState } from "react";
import { BiSolidMessageSquareAdd } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";

import PageLoading from "../componentsOwner/PageLoading";
import { BASE_URL } from "../utils/services";
import Loading from "../components/Loading";
import FloatingAddButton from "../components/FloatingAddButton";

const Owner = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);

  const callHomePage = async () => {
    setLoading(true);
    try {
      const res = await fetch(`${BASE_URL}/owner/owner-home`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      const data = await res.json();
      // console.log(data);

      if (res.status === 401) {
        navigate("/owner/owner-login");
      }

      if (!res.status === 200) {
        const error = new Error(res.error);
        throw error;
      }
      if (data) {
        setUserData(data);
        // loginOwner(data);
      }
    } catch (error) {
      console.log(error);
      navigate("/owner/owner-login");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!userData) {
      callHomePage();
    }
  });

  if (userData && userData.restaurants) {
    return (
      <div className="flex flex-col  justify-center items-center">
        <div className="flex flex-row flex-wrap justify-center gap-4 w-full">
          <div className="w-full bg-white shadow-md rounded-lg p-4 h-max">
            <div className="flex justify-between items-center mb-4">
              <p className="text-2xl font-bold">My Restaurants</p>
              {/* <BiSolidMessageSquareAdd
                  className="cursor-pointer text-reviews"
                  size={25}
                  title="Add Restaurant"
                  onClick={() => navigate("/owner/add-restaurant")}
                /> */}
            </div>
            <div className="divide-y-2  divide-theme/50">
              {userData.restaurants.map((item, index) => (
                <Link
                  title="See Details"
                  to={`/owner/restaurant/${item.id}`}
                  className="block text-theme hover:text-themeHover p-4 rounded-lg bg-gray-300"
                  key={index}
                >
                  <div className="text-lg font-semibold">
                    {index + 1}. {item.name}, {item.location}, {item.city}
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
        <FloatingAddButton title="Add Restaurant">
          <Link to={`/owner/add-restaurant`}>
            <BiSolidMessageSquareAdd
              className="cursor-pointer"
              size={25}
              title="Add Restaurant"
            />
          </Link>
        </FloatingAddButton>
        {loading && <Loading />}
      </div>
    );
  } else {
    return (
      <>
        <PageLoading link={"/"} />
      </>
    );
  }
};

export default Owner;
