import { GrSquare } from "react-icons/gr";

export const formatCurrency = (amount, currency = "INR") => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  }).format(amount);
};

export const customerPhone = (phone) => {
  if (phone) {
    const mobile = phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    return <a href={`tel:${phone}`}>{mobile}</a>;
  }
  return <span className="text-danger font-semibold">Not Provided</span>;
};
export const customerEmail = (email) => {
  if (email) {
    return <a href={`mailto:${email}`}>{email}</a>;
  }
  return <span className="text-danger font-semibold">Not Provided</span>;
};
export const foodType = (type, size) => {
  switch (type) {
    case "PURE-VEG":
      return (
        <span className="inline-flex items-center">
          &nbsp;
          <GrSquare color="green" size={size} />
        </span>
      );
    case "EGG-ONLY":
      return (
        <span className="inline-flex items-center">
          &nbsp;
          <GrSquare className="text-yellow-800" size={size} />
        </span>
      );
    case "NON-VEG":
      return (
        <span className="inline-flex items-center">
          &nbsp;
          <GrSquare color="red" size={size} />
        </span>
      );
    default:
      return <span></span>;
  }
};

export const getInitials = (name) => {
  if (!name) return ""; // Return empty string if name is undefined or null
  const names = name.split(" ");
  const firstName = names[0];
  const lastName = names.length > 1 ? names[names.length - 1] : "";
  return (
    firstName.charAt(0).toUpperCase() +
    (lastName ? lastName.charAt(0).toUpperCase() : "")
  );
};
