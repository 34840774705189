import React, { useState, useEffect, useCallback } from "react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { Fragment, useRef } from "react";
import { BASE_URL } from "../utils/services";
import pluralize from "pluralize";
import { customerPhone, formatCurrency } from "../utils/helper";
import ReactToPrint from "react-to-print";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import { FaFileInvoice } from "react-icons/fa";
import { FaKitchenSet, FaFilePdf } from "react-icons/fa6";
const OrderDetailModal = ({ restaurantId, orderId, isOpen, closeModal }) => {
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const invoiceRef = useRef();
  const kitRef = useRef();
  const handleGeneratePDF = () => {
    const {
      id,
      customer,
      basePriceTotal,
      discountAmount,
      taxAmount,
      serviceChargeAmount,
      totalAmount,
      createdAt,

      restaurant,
      // status,
      // paymentStatus,
      // orderType,
      // metaData,
      // taxableAmount,
      // taxRate,
      // rounddOff,
    } = orderDetails;
    const doc = new jsPDF({
      // orientation: "p",
      // unit: "mm",
      // format: "a4",
      putOnlyUsedFonts: false,
    });
    var pageWidth =
      doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    // const pageHeight =
    //   doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    let y = 10;
    doc.text(restaurant.name, pageWidth / 2, y, {
      align: "center",
    });
    doc.text(
      `${restaurant.location}, ${restaurant.area}, ${restaurant.city}`,
      pageWidth / 2,
      (y += 10),
      {
        align: "center",
      }
    );
    doc.text(`GSTIN: ${restaurant.gstNumber}`, pageWidth / 2, (y += 10), {
      align: "center",
    });

    doc.text(`Order: #${id}`, 10, (y += 10));
    doc.text(
      `Date: ${moment(createdAt).format("DD-MM-YYYY hh:MM A")}`,
      10,
      (y += 10)
    );

    doc.text(
      `Customer Phone: ${customer?.phone || "Not Provided"}`,
      10,
      (y += 10)
    );

    const orderItems = orderDetails.orderItems.map((item) => [
      item.menuItem.name,
      item.quantity,
      item.price,
      item.price * item.quantity,
    ]);
    doc.autoTable({
      head: [["Item", "Qty", "Rate", "Total"]],
      body: orderItems,
      startY: (y += 10),
    });

    y = doc.previousAutoTable.finalY;
    doc.text(`Item Total: ${basePriceTotal}`, 10, (y += 10));
    discountAmount && doc.text(`Discount: ${discountAmount}`, 10, (y += 10));
    taxAmount && doc.text(`GST: ${taxAmount}`, 10, (y += 10));
    serviceChargeAmount &&
      doc.text(`Service Charge: ${serviceChargeAmount}`, 10, (y += 10));
    doc.text(`Grand Total: ${totalAmount}`, 10, (y += 10));

    doc.save(`invoice_${id}.pdf`);
  };
  // Fetch order details when modal is opened
  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        if (isOpen && orderId) {
          setLoading(true);
          const response = await fetch(
            `${BASE_URL}/owner/orders/${restaurantId}/${orderId}`,
            {
              method: "GET",
              credentials: "include",
            }
          );
          if (response.status === 200) {
            const detail = await response.json();
            // console.log(detail);
            setOrderDetails(detail);
            //   setLoading(false);
          } else {
            //   setLoading(false);
            throw new Error("Failed to fetch order details");
          }
        }
      } catch (error) {
        console.error("Error fetching order details:", error);
        //   setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    fetchOrderDetails();
  }, [isOpen, orderId]);

  const renderOrderDetails = useCallback(() => {
    if (loading) {
      return <p>Loading...</p>;
    }

    if (!orderDetails) {
      return <p>No order details available.</p>;
    }
    const {
      id,
      customer,
      status,
      orderType,
      metaData,
      basePriceTotal,
      discountAmount,
      taxRate,
      taxAmount,
      serviceChargeAmount,
      rounddOff,
      totalAmount,
      createdAt,
      orderItems,
      restaurant,
      // paymentStatus,
      // taxableAmount,
    } = orderDetails;
    return (
      <div>
        <div className="invoice-container hidden print:block" ref={kitRef}>
          <div className="flex flex-col items-center mb-2">
            <p className="text-sm font-bold">{restaurant.name}</p>
            <p className="text-xs font-bold text-gray-600">
              {`${restaurant.location}, ${restaurant.area}, ${restaurant.city}`}
            </p>
          </div>
          <div className="text-sm font-bold text-gray-800 mb-2 flex justify-between">
            <span>Order - #{id}</span>
            <span className="text-sm text-gray-600">{status}</span>
          </div>
          <div className="space-y-4 print:space-y-1">
            {/* Order Information */}
            <div className="border-b pb-4 print:pb-2">
              <div className="flex justify-between">
                <div>
                  <p className="text-xs text-gray-600">
                    <strong>Customer: </strong> {customerPhone(customer?.phone)}
                  </p>
                  <p className="text-xs text-gray-600">
                    <strong>Order Date:</strong>{" "}
                    {new Date(createdAt).toLocaleString()}
                  </p>
                  <p className="text-xs text-gray-600">
                    <strong>Order Type:</strong> {orderType}
                  </p>
                </div>
              </div>
              {metaData &&
                metaData.map((data, index) => (
                  <p key={index} className="text-xs text-gray-600">
                    <strong>{data.dataName}:</strong> {data.dataValue}
                  </p>
                ))}
            </div>
            {/* Order Items */}
            <div className="border-b pb-2">
              <h5 className="text-sm font-medium text-gray-700">Order Items</h5>
              <ol className="space-y-1 list-decimal pl-5" type="1">
                {orderItems.map((item) => (
                  <li key={item.id} className="text-xs text-gray-600">
                    <p>
                      <span>
                        {pluralize(
                          item.menuItem.name,
                          parseInt(item.quantity),
                          true
                        )}
                      </span>{" "}
                      {item.remarks && <span> - {item.remarks}</span>}
                    </p>
                  </li>
                ))}
              </ol>
            </div>
            <div className="py-2 flex flex-col items-center">
              <p className="text-xs text-gray-600">🙏 Thank you 🙏</p>
              {/* <p className="text-xs text-gray-600">&copy;</p> */}
            </div>
          </div>
        </div>
        <div className="invoice-container _88mm" ref={invoiceRef}>
          <DialogTitle>
            <div className="hidden print:block">
              <div className="flex flex-col items-center mb-2">
                <p className="text-sm font-bold">{restaurant.name}</p>
                <p className="text-xs font-bold text-gray-600">
                  {`${restaurant.location}, ${restaurant.area}, ${restaurant.city}`}
                </p>
                {restaurant.gstNumber && (
                  <p className="text-xs font-bold text-gray-600">
                    GSTIN: {restaurant.gstNumber}
                  </p>
                )}
              </div>
            </div>
            <div className="text-xl print:text-sm font-bold text-gray-800 mb-4 print:mb-2 flex justify-between">
              <span>Order - #{id}</span>
              <span className="text-sm text-gray-600 print:hidden">
                {status}
              </span>
            </div>
          </DialogTitle>
          <div className="space-y-4 print:space-y-1">
            {/* Order Information */}
            <div className="border-b pb-4 print:pb-2">
              <div className="flex justify-between">
                <div>
                  <p className="text-sm print:text-xs text-gray-600">
                    <strong>Order Date:</strong>{" "}
                    {new Date(createdAt).toLocaleString()}
                  </p>
                  <p className="text-sm text-gray-600 print:hidden">
                    <strong>Order Type:</strong> {orderType}
                  </p>
                  <p className="text-sm print:text-xs text-gray-600 hidden print:block">
                    <strong>Customer: </strong>
                    {customerPhone(customer?.phone)}
                  </p>
                </div>
                <div className="print:hidden">
                  <p className="text-sm font-semibold text-gray-700 text-right">
                    Customer
                  </p>
                  <p className="text-sm text-gray-600">
                    <strong>Phone: </strong>
                    {customerPhone(customer?.phone)}
                  </p>
                </div>
              </div>
              {metaData &&
                metaData.map((data, index) => (
                  <p key={index} className="text-sm text-gray-600 print:hidden">
                    <strong>{data.dataName}:</strong> {data.dataValue}
                  </p>
                ))}
            </div>
            {/* Order Items */}
            <div className="border-b pb-4 print:pb-2">
              <h5 className="text-sm font-medium text-gray-700">Order Items</h5>
              <ul className="space-y-1">
                {orderItems.map((item) => (
                  <li
                    key={item.id}
                    className="flex justify-between text-sm  print:text-xs text-gray-600"
                  >
                    <span>
                      {pluralize(
                        item.menuItem.name,
                        parseInt(item.quantity),
                        true
                      )}
                    </span>
                    <span>{formatCurrency(item.price * item.quantity)}</span>
                  </li>
                ))}
              </ul>
            </div>
            {/* Pricing Details */}
            <div className="border-b pb-4 print:pb-2 print:border-b-0">
              <h5 className="text-lg  print:text-sm font-medium text-gray-700">
                Bill Summary
              </h5>
              <div className="space-y-1">
                <div className="flex justify-between text-sm print:text-xs text-gray-600">
                  <span>Item total</span>
                  <span>₹{basePriceTotal}</span>
                </div>
                {discountAmount && discountAmount > 0 && (
                  <div className="flex justify-between text-sm print:text-xs text-gray-600">
                    <span>Discount</span>
                    <span>-₹{discountAmount}</span>
                  </div>
                )}
                {/* <div className="flex justify-between text-sm text-gray-600">
                  <span>Taxable Amount</span>
                  <span>₹{taxableAmount}</span>
                </div> */}
                {taxAmount && taxAmount > 0 && (
                  <div className="flex justify-between text-sm print:text-xs text-gray-600">
                    <span>GST</span>
                    <span>₹{taxAmount}</span>
                  </div>
                )}
                <div className="flex justify-between text-sm print:text-xs text-gray-600">
                  <span>Service Charge</span>
                  <span>₹{serviceChargeAmount}</span>
                </div>
                {rounddOff && rounddOff > 0 && (
                  <div className="flex justify-between text-sm print:text-xs text-gray-600">
                    <span>Round Off</span>
                    <span>₹{rounddOff}</span>
                  </div>
                )}
                <div className="flex justify-between text-sm text-gray-900 font-bold">
                  <span>Grand Total</span>
                  <span>₹{totalAmount}</span>
                </div>
              </div>
            </div>
          </div>
          {taxAmount && taxAmount > 0 && (
            <div className="hidden print:block">
              <div className="border-t py-2 border-b-0">
                <table className="w-full">
                  <thead>
                    <tr>
                      <th className="text-xs text-gray-600">Tax</th>
                      <th className="text-xs text-gray-600 text-right">
                        Tax Rate
                      </th>
                      <th className="text-xs text-gray-600 text-right">
                        Tax Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-xs text-gray-600">CGST</td>
                      <td className="text-xs text-gray-600 text-right">
                        {taxRate / 2}%
                      </td>
                      <td className="text-xs text-gray-600 text-right">
                        {(taxAmount / 2).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-xs text-gray-600">SGST</td>
                      <td className="text-xs text-gray-600 text-right">
                        {taxRate / 2}%
                      </td>
                      <td className="text-xs text-gray-600 text-right">
                        {(taxAmount / 2).toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
          <div className="hidden print:block">
            <div className="border-t py-2 border-b-0 flex flex-col items-center">
              <p className="text-xs text-gray-600">🙏 Thank you 🙏</p>
              {/* <p className="text-xs text-gray-600">&copy;</p> */}
            </div>
          </div>
          <div className="mt-6 flex justify-end gap-2 print:hidden">
            <ReactToPrint
              trigger={() => (
                <button className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 inline-flex gap-1 items-center">
                  <FaKitchenSet />{" "}
                  <span className="hidden sm:inline-block">KIT</span>
                </button>
              )}
              content={() => kitRef.current}
            />
            <ReactToPrint
              trigger={() => (
                <button className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 inline-flex gap-1  items-center">
                  <FaFileInvoice />
                  <span className="hidden sm:inline-block">Invoice</span>
                </button>
              )}
              content={() => invoiceRef.current}
            />
            <button
              onClick={handleGeneratePDF}
              className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 inline-flex gap-1 items-center"
            >
              <FaFilePdf />
              <span className="hidden sm:inline-block">PDF</span>
            </button>
            <button
              onClick={closeModal}
              className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  }, [loading, orderDetails]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg mx-auto">
                {renderOrderDetails()}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default OrderDetailModal;
