import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { BASE_URL } from "../utils/services";
import { customerPhone, formatCurrency } from "../utils/helper";
import { FaFileInvoice } from "react-icons/fa";
import { FaKitchenSet } from "react-icons/fa6";
import { FaEye } from "react-icons/fa";
import OrderDetailModal from "./OrderDetailModal";

const PosOrderList = forwardRef(function PosOrderList({ restaurantId }, ref) {
  const [orders, setOrders] = useState([]);
  const [refreshCount, setRefreshCount] = useState(0);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const openViewModal = (orderId) => {
    setSelectedOrderId(orderId);
    setIsViewModalOpen(true);
  };

  const closeViewModal = () => {
    setIsViewModalOpen(false);
    setSelectedOrderId(null);
  };
  useImperativeHandle(ref, () => ({
    refresh: () => {
      setRefreshCount((prevCount) => prevCount + 1);
    },
  }));
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}/owner/pos/${restaurantId}/orders`,
          {
            method: "GET",
            credentials: "include",
          }
        );
        const data = await response.json();
        setOrders(data);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchData();
  }, [restaurantId, refreshCount]);

  return (
    <>
      <ul className="divide-y divide-indigo-500">
        {orders.map((order) => (
          <li key={order.id} className="bg-gray-200 text-gray p-2 text-sm">
            {/* <p>Order ID: {order.id}</p> */}
            <div className="flex justify-between">
              <h5 className="text-xl font-bold text-gray-800">
                Order - #{order.id}
              </h5>
              <div className="flex justify-end gap-2">
                <FaKitchenSet
                  onClick={() => {
                    console.log("print for kitchen");
                  }}
                  className="cursor-pointer text-gray-800 hover:text-black"
                  title="Kitchen Instruction Tool"
                />
                <FaFileInvoice
                  className="cursor-pointer text-gray-800 hover:text-black"
                  title="Invoice"
                />
                <FaEye
                  className="cursor-pointer text-gray-800 hover:text-black"
                  title="Order Detail"
                  onClick={() => openViewModal(order.id)}
                />
              </div>
            </div>
            <p className="text-sm text-gray-600">
              <strong>Customer: </strong> {customerPhone(order.customer?.phone)}
            </p>
            <div className="flex justify-between text-sm text-gray-600">
              <p>
                <strong>Status:</strong>{" "}
                <span className="font-semibold">{order.status}</span>
              </p>
              <p className="font-bold">{formatCurrency(order.totalAmount)}</p>
            </div>
          </li>
        ))}
      </ul>
      <OrderDetailModal
        restaurantId={restaurantId}
        orderId={selectedOrderId}
        isOpen={isViewModalOpen}
        closeModal={closeViewModal}
      />
    </>
  );
});
export default PosOrderList;
